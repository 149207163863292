import React, { useState } from 'react'
import { Button, Dropdown, Form, Offcanvas, Pagination, Table } from 'react-bootstrap';
import { ActionDropdown, DataTable, PageTitle } from '../components';
import { FiFilter } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";

const CouponMaster = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showNew, setShowNew] = useState(false);

const cardHeader=()=><div class="card-header border-0 card-header-content-sm-between">
          
<div class="mb-2 mb-sm-0">
      <div class="input-group">
        {/* <div class="tom-select-custom text-secondary">
        <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Services
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </div> */}
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Title ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>

</div>

<div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
  
  <div class="col-sm-auto">
    <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowFilter(true)}}><FiFilter size={"1rem"}/> Filter</button>
  </div>

  <div class="col-sm-auto">
  <button onClick={()=>{setShowNew(true)}} className="btn btn-secondary">+ Add New Coupon  </button>
  </div>
  <Offcanvas show={showFilter} onHide={()=>{setShowFilter(false)}} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <form>

                  <div class="tom-select-custom mb-3">
                    <label class="form-label">Search / Select Service</label>
                    <select class="js-select form-select" autocomplete="off"
                          data-hs-tom-select-options='{
                            "placeholder": "Status...",
                            "hideSearch": true
                          }'>
                      <option value="4">Flight</option>
                      <option value="1">Flight 1</option>
                    </select>
                  </div>

                  <div class="tom-select-custom mb-3">
                    <label class="form-label">Select Airline Type</label>
                    <select class="js-select form-select" autocomplete="off"
                          data-hs-tom-select-options='{
                            "placeholder": "Status...",
                            "hideSearch": true
                          }'>
                      <option value="4">Select airline</option>
                      <option value="1">Select airline 1</option>
                    </select>
                  </div>

                  <div class="col-auto mt-4">
                    <button type="button" class="btn btn-primary me-3">SEARCH</button>
                    <button type="button" class="btn btn-outline-secondary">RESET</button>
                  </div>
                  
                </form>
        </Offcanvas.Body>
      </Offcanvas>
  <Offcanvas className="w-50" show={showNew} onHide={()=>{setShowNew(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Add New Coupon</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>

          <div class="row">
              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Service *</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="1">Select service</option>
                        <option value="2">Select service 1</option>
                      </select>
                  </div>
              </div>
              
              <div class="col-md-6 mb-3">
                  <label class="form-label" for="exampleFormControlInput1"> Title *</label>
                  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
              </div>
          </div>

          <div class="row">
              <div class="col-md-12">
                  <div class="form-check mb-3">
                      <input type="checkbox" id="formDiscount" class="form-check-input" checked/>
                      <label class="form-check-label" for="formDiscount">Discount on convenience</label>
                  </div>
              </div>
          </div>
          
          <div class="row">
              <div class="col-md-6">
                  <label class="form-label" for="exampleFormControlTextarea1">Description *</label>
                  <textarea id="exampleFormControlTextarea1" class="form-control" placeholder="Enter here..." rows="4"></textarea>
              </div>
              
              <div class="col-md-6 mb-3">
                  <label class="form-label" for="exampleFormControlTextarea1">Terms & Conditions *</label>
                  <textarea id="exampleFormControlTextarea1" class="form-control" placeholder="Enter here..." rows="4"></textarea>
                </div>
          </div>

          <div class="row">
              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Usage Type *</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="4">Select usage type</option>
                        <option value="1">Select usage type 1</option>
                      </select>
                    </div>
              </div>
              
              <div class="col-md-6 mb-3">
                  
                <div class="form-group">
                  <label class="form-label">Expiry Date (MM/DD/YYYY) *</label>
                  <div  class="js-flatpickr flatpickr-custom input-group">
                    <input type="text" class="js-flatpickr flatpickr-custom-form-control form-control" placeholder="Select expire date"
                    data-hs-flatpickr-options='{
                        "dateFormat": "d/m/Y"
                    }'/>

                    <div class="input-group-prepend input-group-text" data-bs-toggle>
                      <i class="bi-calendar-week"></i>
                    </div>
                  </div>
                </div>
                  
              </div>

              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Discount Type *</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="4">Select Discount Type</option>
                        <option value="1">Select Discount Type 1</option>
                      </select>
                    </div>
              </div>

              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Credit Type *</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="4">Select Credit Type</option>
                        <option value="1">Select Credit Type 1</option>
                      </select>
                    </div>
              </div>

              <div class="col-md-6 mb-3">
                  <label class="form-label" for="exampleFormControlInput1"> Discount Value (In Number) * </label>
                  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter discount value"/>
              </div>

              <div class="col-md-6 mb-3">
                  <label class="form-label" for="exampleFormControlInput1"> Minimum Amount to Get Coupon</label>
                  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter amount"/>
              </div>

              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Airline</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="4">Select airline</option>
                        <option value="1">Select airline 1</option>
                      </select>
                    </div>
              </div>

              <div class="col-md-6 mb-3">
                  <label class="form-label" for="exampleFormControlInput1"> Max Discount Allowed by Coupon </label>
                  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter discount"/>
              </div>

              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Airline Type</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="4">Select airline Type</option>
                        <option value="1">Select airline Type 1</option>
                      </select>
                    </div>
              </div>

              <div class="col-md-6 mb-3">
                  <label class="form-label" for="exampleFormControlInput1"> Minimum Passenger Needed </label>
                  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter here"/>
              </div>

              <div class="col-md-6">
                  <label class="form-label" for="exampleFormControlInput1"> Active / Deactive </label>
                  <div class="form-check form-switch mb-4">
                      <input type="checkbox" class="form-check-input" id="formSwitch1"/>
                      <label class="form-check-label" for="formSwitch1">Deactive</label>
                  </div>
              </div>

              <div class="col-md-6">
                  <div class="tom-select-custom mb-3">
                      <label class="form-label">Destination City</label>
                      <select class="js-select form-select" autocomplete="off"
                            data-hs-tom-select-options='{
                              "placeholder": "Status...",
                              "hideSearch": true
                            }'>
                        <option value="4">Select / search destination</option>
                        <option value="1">Select / search destination 1</option>
                      </select>
                    </div>
              </div>

          </div>

          <div class="col-auto mt-4">
            <button type="button" class="btn btn-primary me-3">SUBMIT</button>
            <button type="button" class="btn btn-outline-secondary">CANCEL</button>
          </div>
          
        </form>
</Offcanvas.Body>
</Offcanvas>
  
</div>
</div>

const updateForm=()=> <form>

<div class="row">
    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Service *</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="1">Select service</option>
              <option value="2">Select service 1</option>
            </select>
        </div>
    </div>
    
    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Title *</label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="form-check mb-3">
            <input type="checkbox" id="formDiscount" class="form-check-input" checked/>
            <label class="form-check-label" for="formDiscount">Discount on convenience</label>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <label class="form-label" for="exampleFormControlTextarea1">Description *</label>
        <textarea id="exampleFormControlTextarea1" class="form-control" placeholder="Enter here..." rows="4"></textarea>
    </div>
    
    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlTextarea1">Terms & Conditions *</label>
        <textarea id="exampleFormControlTextarea1" class="form-control" placeholder="Enter here..." rows="4"></textarea>
      </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Usage Type *</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="4">Select usage type</option>
              <option value="1">Select usage type 1</option>
            </select>
          </div>
    </div>
    
    <div class="col-md-6 mb-3">
                
      <div class="form-group">
        <label class="form-label">Expiry Date (MM/DD/YYYY) *</label>
        <div  class="js-flatpickr flatpickr-custom input-group">
          <input type="text" class="js-flatpickr flatpickr-custom-form-control form-control" placeholder="Select expire date"
          data-hs-flatpickr-options='{
              "dateFormat": "d/m/Y"
          }'/>

          <div class="input-group-prepend input-group-text" data-bs-toggle>
            <i class="bi-calendar-week"></i>
          </div>
        </div>
      </div>
        
    </div>

    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Discount Type *</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="4">Select Discount Type</option>
              <option value="1">Select Discount Type 1</option>
            </select>
          </div>
    </div>

    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Credit Type *</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="4">Select Credit Type</option>
              <option value="1">Select Credit Type 1</option>
            </select>
          </div>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Discount Value (In Number) * </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter discount value"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Minimum Amount to Get Coupon</label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter amount"/>
    </div>

    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Airline</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="4">Select airline</option>
              <option value="1">Select airline 1</option>
            </select>
          </div>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Max Discount Allowed by Coupon </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter discount"/>
    </div>

    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Airline Type</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="4">Select airline Type</option>
              <option value="1">Select airline Type 1</option>
            </select>
          </div>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Minimum Passenger Needed </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter here"/>
    </div>

    <div class="col-md-6">
        <label class="form-label" for="exampleFormControlInput1"> Active / Deactive </label>
        <div class="form-check form-switch mb-4">
            <input type="checkbox" class="form-check-input" id="formSwitch1"/>
            <label class="form-check-label" for="formSwitch1">Deactive</label>
        </div>
    </div>

    <div class="col-md-6">
        <div class="tom-select-custom mb-3">
            <label class="form-label">Destination City</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Status...",
                    "hideSearch": true
                  }'>
              <option value="4">Select / search destination</option>
              <option value="1">Select / search destination 1</option>
            </select>
          </div>
    </div>

</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">SUBMIT</button>
  <button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>

const  tableHead= <tr>
<th>Sr. No.</th>
<th>Title</th>
<th>Service</th>
<th>Usage Type</th>
<th>Credit Type</th>
<th>Convenience Discount</th>
<th>Discount Setting</th>
<th>Shopping Point Discount</th>
<th>OExpiry Date(MM/DD/YYYY)</th>
<th>Min. Amount to Apply</th>
<th>Max Amount to Apply</th>
<th>Destination City</th>
<th>Airline Type</th>
<th>Airline</th>
<th>Passenger Count</th>
<th>Active Status</th>
<th>Action</th>
</tr>

const tableRow= <tr>
<td>VIPSFLY50</td>
<td>Flight</td>
<td>Single Time +Expiration</td>
<td>Flat</td>
<td>Yes</td>
<td>25.00%</td>
<td>25.00%</td>
<td>12/23/2023</td>
<td>0.00</td>
<td>0.00</td>
<td>-</td>
<td>International</td>
<td>-</td>
<td>1</td>
<td class=""> Yes</td>
<td>
  <ActionDropdown updateTitle={"Edit Coupon Master"} updateForm={updateForm} options={["Delete"]} hasClass={true}/>
</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
    <PageTitle title={"Coupon Master"}/>
    <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

  </main>
  )
}

export default CouponMaster