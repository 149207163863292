import React, { useState } from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import { IoCalendarOutline,  } from "react-icons/io5";

const UserDetails = () => {
const [showUpdate, setShowUpdate] = useState(false)

  const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">
    
        <div class="input-group">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Username
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>
    
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
    
    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
            </div>
        </div>
    </div>

    <div class="col-sm-auto ">
        <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead= <tr>
<th>Sr. No.</th>
<th>Unique Id</th>
<th>Register Date</th>
<th>Name</th>
<th>User Name</th>
<th>Gold Grams</th>
<th>Silver Grams</th>
<th>State Id</th>
<th>State Name</th>
<th>City Id</th>
<th>City Name</th>
<th>Is Active</th>
<th>Update Date</th>
<th>User Email</th>
<th>Date of Birth</th>
<th>Nominee</th>
<th>Nominee Date of Birth</th>
<th>Nominee Relation</th>
<th>User KYC Status</th>
<th>Action</th>
</tr>

const tableRow=<tr>
<td>15752532</td>
<td>4/29/2023 12:03:59 PM</td>
<td>Sonika Kumari</td>
<td>9652536253</td>
<td>0</td>
<td>1.0592</td>
<td>Do7Wjq3d</td>
<td>Maharashtra</td>
<td>Wfhnjnhbj</td>
<td>Nanded</td>
<td class="text-success">Active</td>
<td>4/29/2023 12:03:59 PM</td>
<td>Subhgsdbjb125@gmail.com</td>
<td>1992-03-07</td>
<td>Mahesh Naik</td>
<td>2000-09-13</td>
<td>Brother</td>
<td class="text-warning">Pending</td>
<td><button type="button" class="btn btn-secondary" onClick={()=>{setShowUpdate(true)}}>Edit</button></td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
     <PageTitle title={"User Details"}/>
     <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

    <Offcanvas show={showUpdate} onHide={()=>{setShowUpdate(false)}} placement='end' className="w-50">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit User Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <form>

<div class="row">
    <div class="col-md-6 mb-3">
        <label class="form-label" for="uniqueid"> Unique Id  </label>
        <input type="text" id="uniqueid" class="form-control" placeholder="Enter Unique Id "/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="name"> Name </label>
        <input type="text" id="name" class="form-control" placeholder="Enter Name"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="useremail"> User Email </label>
        <input type="text" id="useremail" class="form-control" placeholder="Enter User Email"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="mobilenumber"> Mobile Number </label>
        <input type="text" id="mobilenumber" class="form-control" placeholder="Enter Mobile Number"/>
    </div>

    <div class="col-md-6 mb-3">
      <label class="form-label" for="exampleFormControlInput1"> Select State </label>
      <div class="tom-select-custom">
          <select class="js-select form-select" autocomplete="off"
                data-hs-tom-select-options='{
                  "placeholder": "Select State",
                  "hideSearch": true
                }'>
            <option value="">Select State</option>
            <option value="1">Maharashtra</option>
            <option value="2">Karnataka</option>
          </select>
      </div>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Select City </label>
        <div class="tom-select-custom">
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Select City",
                    "hideSearch": true
                  }'>
              <option value="">Select City</option>
              <option value="1">Pune</option>
              <option value="2">Kolhapur</option>
            </select>
        </div>
    </div>
    
    <div class="col-md-6 mb-3">
        <label class="form-label" for="goldgrams"> Gold Grams </label>
        <input type="text" id="goldgrams" class="form-control" placeholder="Enter Gold Grams"/>
    </div>

    <div class="col-md-6 mb-3">
      <label class="form-label" for="silvergrams"> Silver Grams </label>
      <input type="text" id="silvergrams" class="form-control" placeholder="Enter Silver Grams"/>
    </div>

    <div class="col-md-6 mb-3">
      <label class="form-label" for="silvergrams"> Add Date </label>
      <div class="input-group">
          <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
      
          <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
          <i class="bi-calendar-week"></i>
          </div>
      </div>
  </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Is Active </label>
        <div class="tom-select-custom">
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Select Status",
                    "hideSearch": true
                  }'>
              <option value="">Select Status</option>
              <option value="1">Active</option>
              <option value="2">Deactive</option>
            </select>
        </div>
    </div>
    
    <div class="col-md-6 mb-3">
      <label class="form-label" for="exampleFormControlInput1"> Update Date </label>
      <input type="text" class="js-flatpickr form-control flatpickr-custom" placeholder="Enter update date"
      data-hs-flatpickr-options='{
        "dateFormat": "d/m/Y"
      }'/>
    </div>
    
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">UPDATE</button>
  <button type="button" class="btn btn-outline-secondary">CANCEL</button>
</div>

</form>
        </Offcanvas.Body>
      </Offcanvas>
    
  </main>
  )
}

export default UserDetails