import React, { useState } from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiFilter, FiUpload } from "react-icons/fi";
import { IoCalendarOutline, IoRefreshSharp } from "react-icons/io5";

const BuySellReport = () => {
const [showFilter, setShowFilter] = useState(false)
const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
          
<div class="mb-2 mb-sm-0">

      <div class="input-group">
        <div class="tom-select-custom text-secondary">
        <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Username
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </div>
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>
  
</div>

<div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
  
  <div class="col-sm-auto">
      <div class="input-group">
          <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
      
          <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
          <IoCalendarOutline size={"1rem"}/>
          </div>
      </div>
  </div>

    
  <div class="col-sm-auto">
      <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowFilter(true)}}><FiFilter size={"1rem"}/> Filter</button>
  </div>
  <Offcanvas show={showFilter} onHide={()=>{setShowFilter(false)}} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <form>

<div class="tom-select-custom mb-3">
  <label class="form-label">Select Metal Type</label>
  <select class="js-select form-select" autocomplete="off"
        data-hs-tom-select-options='{
          "placeholder": "Select Metal Type",
          "hideSearch": true
        }'>
    <option value="">All/Gold/Silver</option>
    <option value="1">Gold</option>
    <option value="2">Silver</option>
  </select>
</div>

<div class="tom-select-custom mb-3">
    <label class="form-label">Select Transaction Type</label>
    <div class="tom-select-custom">
        <select class="js-select form-select" autocomplete="off"
              data-hs-tom-select-options='{
                "placeholder": "Select Transaction Type",
                "hideSearch": true
              }'>
          <option value="">Select Transaction Type</option>
          <option value="1">Buy</option>
          <option value="2">Sell</option>
        </select>
    </div>
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">APPLY</button>
  <button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>
        </Offcanvas.Body>
      </Offcanvas>

  <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
  </div>
  
</div>
</div>

const tableHead=   <tr>
<th>Sr. No.</th>
<th>Id</th>
<th>User Name</th>
<th>Transaction Date</th>
<th>Unique Id</th>
<th>Name</th>
<th>Transaction Type</th>
<th>Quantity</th>
<th>Amount</th>
<th>Tax</th>
<th>Total Amount</th>
<th>Metal Type</th>
<th>Rate</th>
<th>Transaction Id</th>
<th>Merchant Transaction Id</th>
<th>Current Gold Balance</th>
<th>Current Silver Balance</th>
<th>Invoice Number</th>
<th>Transaction Status</th>
<th>App Type</th>
<th>Current App Version</th>
</tr>

const tableRow= <tr>
<td>960</td>
<td>9652536253</td>
<td>4/29/2023 12:03:59 PM</td>
<td>1661781</td> 
<td>Sonika Kumari</td>
<td>Buy</td>
<td>0.001</td>
<td>6.80</td>
<td>0.20</td>
<td>7.00</td>
<td>gold</td> 
<td>6186.71</td>
<td>vw817316826539685459068586</td>
<td>VIPS20230428092248</td>
<td>0.0258</td>
<td>0</td>
<td>GVW0424626</td>
<td class="text-success"> Success</td>
<td>Android</td>
<td>10.28.0</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
<PageTitle title={"Buy/Sell Report"}/>
      <div class="row mb-5">
        <div class="col-md-3">
          <div class="card card-bordered h-100">
            <div class="card-body">
              <h3 class="fs-5">Total Buy Amount</h3>
              <p class="fs-2 fw-bold text-secondary mb-0">62432.51</p>
            </div>
          </div>
        </div>
      
        <div class="col-md-3">
          <div class="card card-bordered h-100">
            <div class="card-body">
              <h3 class="fs-5">Total Sell Amount</h3>
              <p class="fs-2 fw-bold text-secondary mb-0">15,45,94,225</p>
            </div>
          </div>
        </div>

      </div>

<DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

    
  </main>
  )
}

export default BuySellReport