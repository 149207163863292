import React, { useState } from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import { FiFilter, FiUpload} from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const FlightBooking = () => {
const [showFilter, setShowFilter] = useState(false)

  const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">
    <form>
      <div class="input-group">
        <div class="tom-select-custom text-secondary">
          <select class="js-select form-select bg-light " autocomplete="off"
                  data-hs-tom-select-options='{
                        "dropdownWidth": "300px"
                      }'>
            <option class="text-secondary" value="1" selected>Username</option>
            <option value="2">Nikola</option>
            <option value="3">Nikola Tesla</option>
            <option value="4">Arnold Schwarzenegger</option>
          </select>
        </div>
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>
    </form>
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">

    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
            </div>
        </div>
    </div>

    <div class="col-sm-auto border border-1 bg-light ps-3 rounded">
      <div class="row align-items-center gx-0">
        <div class="col">
          <span class="text-secondary me-2">Status:</span>
        </div>

        <div class="col-auto">
          <div class="tom-select-custom tom-select-custom-end">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>All </Dropdown.Toggle>
<Dropdown.Menu>
<Dropdown.Item href="#/action-1">All</Dropdown.Item>
<Dropdown.Item href="#/action-2">Debit</Dropdown.Item>
<Dropdown.Item href="#/action-3">Credit</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-sm-auto">
      <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowFilter(true)}}><FiFilter size={"1rem"}/> Filter</button>
  </div>
  <Offcanvas show={showFilter} onHide={()=>{ setShowFilter(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Filter</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>

<div class="tom-select-custom mb-3">
<label class="form-label">Search By</label>
<select class="js-select form-select" autocomplete="off"
data-hs-tom-select-options='{
  "placeholder": "Search By",
  "hideSearch": true
}'>
<option value="">Search By</option>
<option value="1">Contact Number</option>
<option value="2">Demo 1</option>
</select>
</div>

<div class="col-auto mt-4">
<button type="button" class="btn btn-primary me-3">SEARCH</button>
<button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>
</Offcanvas.Body>
</Offcanvas>

    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead=  <tr>
<th>Sr. No.</th>
<th>Username</th>
<th>Booking Id</th>
<th>Flight Id</th>
<th>From City</th>
<th>To City</th>
<th>No. of Ticket</th>
<th>Ticket Id</th>
<th>Date of Joining</th>
<th>Ticket Status</th>
<th>Ticket Fare</th>
<th>Coupon Name</th>
<th>Coupon Discount</th>
<th>Shopping Point Discount</th>
<th>Prime Point Discount</th>
<th>Convenience Fee</th>
<th>Total Payable Amount</th>
<th>Contact No.</th>
<th>Email</th>
<th>No. of Adult</th>
<th>No. of Child</th>
<th>No. of Infant</th>
</tr>

const tableRow=   <tr>
<td>
<span className='me-1'>9923290044</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>57468820</td>
<td>590</td>
<td>Mumbai</td>
<td>Goa</td>
<td>5</td>
<td>UH417B</td>
<td>4/29/2023 12:03:59 PM</td>
<td>Cancelled</td>
<td>11197.00</td>
<td>FLIGHT100</td>
<td>500</td>
<td>500</td>
<td>500</td>
<td>250</td>
<td>21197.00</td>
<td>992328525 </td>
<td>devlopment@vipswallet.com</td>
<td>1</td>
<td>0</td>
<td>0</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
    <PageTitle title={"Flight Booking Report"}/>
    <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

  </main>
  )
}

export default FlightBooking