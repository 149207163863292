import React from 'react'
import { PageTitle } from '../../components'

const Service = () => {
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
      <PageTitle title={"Service"}/>
      <div class="card mb-3 mb-lg-5">
        <div class="card-body">
          
            <div class="col-sm-6 tom-select-custom mb-3">
                <label class="form-label">Select Service</label>
                <select class="js-select form-select" autocomplete="off"
                      data-hs-tom-select-options='{
                        "placeholder": "Select Service",
                        "hideSearch": true
                      }'>
                  <option value="">Select Service</option>
                  <option value="1">Select Service 1</option>
                  <option value="2">Select Service 2</option>
                </select>
            </div>

            <div class="col-sm-6 tom-select-custom mb-3">
                <label class="form-label">Select Operator</label>
                <select class="js-select form-select" autocomplete="off"
                      data-hs-tom-select-options='{
                        "placeholder": "Select Operator",
                        "hideSearch": true
                      }'>
                  <option value="">Select Operator</option>
                  <option value="1">Select Operator 1</option>
                  <option value="2">Select Operator 2</option>
                </select>
            </div>

            <div class="col-sm-6 tom-select-custom mb-3">
                <label class="form-label">Select API</label>
                <select class="js-select form-select" autocomplete="off"
                      data-hs-tom-select-options='{
                        "placeholder": "Select API",
                        "hideSearch": true
                      }'>
                  <option value="">Select API</option>
                  <option value="1">Select API 1</option>
                  <option value="2">Select API 2</option>
                </select>
            </div>

            <div class="col-sm-6 tom-select-custom mb-3">
                <label class="form-label">Select Bill Mode</label>
                <select class="js-select form-select" autocomplete="off"
                      data-hs-tom-select-options='{
                        "placeholder": "Select Bill Mode",
                        "hideSearch": true
                      }'>
                  <option value="">Select Bill Mode</option>
                  <option value="1">Online</option>
                  <option value="2">Offline</option>
                </select>
            </div>
            
            <div class="col-auto mt-4">
              <button type="button" class="btn btn-primary me-3">UPDATE API</button>
              <button type="button" class="btn btn-outline-secondary">CLEAR</button>
            </div>
            
        </div>
      </div>
    </div>
    
  </main>
  )
}

export default Service