import React, { useState } from "react";
import { Dropdown, Offcanvas, Pagination, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const DataTable = ({
  header,
  tableHead,
  tableRow,
  updateTitle,
  updateForm,
}) => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("4");
  const totalItems = 21;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  ].slice(indexOfFirstItem, indexOfLastItem);
  const tableRows = currentItems.map((item) => (
    <tr>
      <td>{item}</td>
      {tableRow.props.children.map((data) => (
        <td>{data}</td>
      ))}
    </tr>
  ));
  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  }
  return (
    <div class="card">
      {header()}
      <div className="table-responsive datatable-custom">
        <Table
          id="datatable"
          className="table table-striped border-bottom rounded-3 table-thead-bordered table-nowrap table-align-middle card-table"
        >
          <thead class="thead-light">{tableHead}</thead>

          <tbody>{tableRows}</tbody>
        </Table>
      </div>

      <div class="card-footer border-0">
        <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
          <div class="col-sm mb-2 mb-sm-0">
            <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
              <span class="me-2">Rows per page</span>

              <Dropdown>
                <Dropdown.Toggle className="btn btn-light show-row-dropdownbtn">
                  {itemsPerPage}
                </Dropdown.Toggle>

                <Dropdown.Menu className="show-row-dropdown">
                  {["4", "5", "6", "7", "8"].map((number) => (
                    <Dropdown.Item
                      value={number}
                      onClick={() => {
                        setItemsPerPage(number);
                      }}
                    >
                      {number}{" "}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <span class="text-secondary me-2">of</span>
              <span id="datatableWithPaginationInfoTotalQty">21</span>
            </div>
          </div>
          <div class="col-sm-auto">
            <Pagination className="pagination datatable-custom-pagination">
              <Link
                className=" paginate_button previous page-link"
                onClick={currentPage !== 1 && goToPreviousPage}
                // disabled={currentPage === 1}
              >
                Prev
              </Link>
              {paginationItems}
              <Link
                className=" paginate_button next page-link"
                onClick={indexOfLastItem < 21 && goToNextPage}
                // disabled={indexOfLastItem >= 21}
              >
                Next
              </Link>
            </Pagination>
          </div>
        </div>
      </div>

      {/* Update Offcanvas Start */}

      {/* <Offcanvas
        show={showUpdate}
        onHide={() => {
          setShowUpdate(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{updateTitle}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{updateForm()}</Offcanvas.Body>
      </Offcanvas> */}

      {/* Update Offcanvas End */}
    </div>
  );
};

export default DataTable;
