import React from 'react'
import { PageTitle } from '../../components'

const RechargeBrowsePlan = () => {
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
    <PageTitle title={"Recharge Browse Plan API"}/>
      <div class="card mb-3 mb-lg-5">
        <div class="card-body">
          
            <div class="col-sm-6 tom-select-custom mb-3">
              <label class="form-label">Select Recharge Browse Plan API</label>
              <select class="js-select form-select" autocomplete="off"
                      data-hs-tom-select-options='{
                        "placeholder": "Select recharge browse plan API",
                        "hideSearch": true
                      }'>
                  <option value="">Select recharge browse plan API</option>
                  <option value="1">browse plan API 1</option>
                  <option value="2">browse plan API 2</option>
                </select>
            </div>
            
            <div class="col-auto mt-4">
              <button type="button" class="btn btn-primary me-3">SAVE CHANGES</button>
              <button type="button" class="btn btn-outline-secondary">CLEAR</button>
            </div>
            
        </div>
      </div>

    </div>
    
  </main>
  )
}

export default RechargeBrowsePlan