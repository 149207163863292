import React from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const ShoppingPointReport = () => {
  const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
  <div class="mb-2 mb-sm-0">
      <div class="input-group">
        <div class="tom-select-custom text-secondary">
        <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>User Id </Dropdown.Toggle>
<Dropdown.Menu>
<Dropdown.Item href="#/action-1">Services</Dropdown.Item>
<Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
<Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
        </div>
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search User Id ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">

    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
            </div>
        </div>
    </div>
    {/* <script>
        $('#with-calendar-field').daterangepicker({ startDate: '03/05/2005', endDate: '03/06/2005' });
    </script> */}

    <div class="col-sm-auto border border-1 bg-light ps-3 rounded">
      <div class="row align-items-center gx-0">
        <div class="col">
          <span class="text-secondary me-2">Type:</span>
        </div>

        <div class="col-auto">
          <div class="tom-select-custom tom-select-custom-end">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>All </Dropdown.Toggle>
<Dropdown.Menu>
<Dropdown.Item href="#/action-1">All</Dropdown.Item>
<Dropdown.Item href="#/action-2">Debit</Dropdown.Item>
<Dropdown.Item href="#/action-3">Credit</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead= <tr>
<th>Sr. No.</th>
<th>User ID</th>
<th>Transaction Type</th>
<th>Old Point</th>
<th>Point</th>
<th>New Points</th>
<th>Date</th>
<th>Type</th>
<th>Description</th>
</tr>

const tableRow=  <tr>
<td><span className='me-1'>9724771288</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>JIO-Recharge Recharge - Reversal</td>
<td>100.00</td>
<td>100.00</td>
<td>0.00</td>
<td>4/29/2023 12:03:59 PM</td>
<td class="text-danger">Debit</td>
<td>Transfer from user referance <br /> Username: 9510964243</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
     <PageTitle title={"Shopping Point Report"}/>
     <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>
  </main>
  )
}

export default ShoppingPointReport