import React from 'react'
import { DataTable, PageTitle } from '../../components'
import { RiFileCopyLine } from "react-icons/ri";

const LiveRecharge = () => {

 const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">   </div>

  const tableHead= <tr>
  <th>Sr. No.</th>
  <th>Username</th>
  <th>Owner Name</th>
  <th>Operator</th>
  <th>Number</th>
  <th>Transaction Id</th>
  <th>Amount</th>
  <th>Status</th>
  <th>Req. Time</th>
  <th>Mode</th>
  <th>API</th>
  <th>App Type</th>
</tr>
  
  const tableRow=<tr>
  <td><span className='me-1'>1206197006</span> <span className='copy-btn'> <RiFileCopyLine /></span>
  </td>
  <td>shaik jilekha </td>
  <td><span className='me-1'>Indane Gas (Indian oil)</span> <span className='copy-btn'> <RiFileCopyLine /></span>
  </td> 
  <td><span className='me-1'>7057423654</span> <span className='copy-btn'> <RiFileCopyLine /></span>
  </td>
  <td>CC013100BAAA30618734</td>
  <td>1,169.50</td>
  <td class="text-success"> Success</td>
  <td>12:03:59 PM</td>
  <td>Wallet</td>
  <td>FBBPS</td>
  <td>Android</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
<PageTitle title={"Today's Live Recharge Report"}/>

      <div class="row mb-5">
        <div class="col-md-3">
          <div class="card card-bordered h-100">
            <div class="card-body">
              <h3 class="fs-5">Today's Total Amount</h3>
              <p class="fs-2 fw-bold text-secondary mb-0">15,45,94,225</p>
            </div>
          </div>
        </div>
      
        <div class="col-md-3">
          <div class="card card-bordered h-100">
            <div class="card-body">
              <h3 class="fs-5">Today’s Success Transactions</h3>
              <p class="fs-2 fw-bold text-secondary mb-0">15,45,94,225</p>
            </div>
          </div>
        </div>

      </div>
      
      <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow} />
    </div>

    
  </main>
  )
}

export default LiveRecharge