import React, { useState } from 'react'
import { ActionDropdown, DataTable, PageTitle } from '../components'
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { RiFileCopyLine } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { FiFilter, FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";

const ManageUser = () => {
  const [showFilter, setShowFilter] = useState(false)
const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">
<div class="mb-2 mb-sm-0">
  
      <div class="input-group">
        <div class="tom-select-custom text-secondary">
        <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Username
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </div>
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>
  
</div>

<div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
  
  <div class="col-sm-auto border border-1 bg-light ps-3 rounded">
    <div class="row align-items-center gx-0">
      <div class="col">
        <span class="text-secondary me-2">Status:</span>
      </div>

      <div class="col-auto">
        <div class="tom-select-custom tom-select-custom-end">
        <Dropdown>
          <Dropdown.Toggle className='btn btn-light filter-btn'>
            All
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">All</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Active</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Deactive</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-sm-auto">
      <div class="input-group">
          <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
      
          <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
          </div>
      </div>
  </div>
    
  <div class="col-sm-auto">
      <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowFilter(true)}}><FiFilter size={"1rem"}/> Filter</button>
      <Offcanvas show={showFilter} onHide={()=>{ setShowFilter(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Filter</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>

<div class="tom-select-custom mb-3">
  <label class="form-label">Search By</label>
  <select class="js-select form-select" autocomplete="off"
        data-hs-tom-select-options='{
          "placeholder": "Search By",
          "hideSearch": true
        }'>
    <option value="">Search By</option>
    <option value="1">Username</option>
    <option value="2">City</option>
  </select>
</div>

<div class="mb-3">
    <label class="form-label" for="exampleFormControlInput1">Search Username</label>
    <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Search by username"/>
</div>

<div class="tom-select-custom mb-3">
    <label class="form-label">Select City</label>
    <div class="tom-select-custom">
        <select class="js-select form-select" autocomplete="off"
              data-hs-tom-select-options='{
                "placeholder": "Select City",
                "hideSearch": true
              }'>
          <option value="">Select City</option>
          <option value="1">Kolhapur</option>
          <option value="2">Pune</option>
          <option value="3">Nashik</option>
        </select>
    </div>
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">SEARCH</button>
  <button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>
</Offcanvas.Body>
</Offcanvas>
  </div>

  <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
  </div>
  
</div>
</div>

const updateForm=()=> <form>

<div class="row">
    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Username </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Last Name </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> First Name </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Email ID </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Mobile No </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> City </label>
        <div class="tom-select-custom">
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Select City",
                    "hideSearch": true
                  }'>
              <option value="">Select City</option>
              <option value="1">Pune</option>
              <option value="2">Kolhapur</option>
            </select>
        </div>
    </div>
    
    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Alternate Number </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter title"/>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> State </label>
        <div class="tom-select-custom">
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Select City",
                    "hideSearch": true
                  }'>
              <option value="">Select State</option>
              <option value="1">Maharashtra</option>
              <option value="2">Karnataka</option>
            </select>
        </div>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="FormControlAddress">Address</label>
        <textarea id="FormControlAddress" class="form-control" placeholder="Enter Address..." rows="4"></textarea>
    </div>

    <div class="col-md-6 mb-3">
        <label class="form-label" for="exampleFormControlInput1"> Pancard </label>
        <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter pancard number"/>
    </div>
    
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">UPDATE</button>
  <button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>

const tableHead=<tr>
<th>Sr. No.</th>
<th>Username</th>
<th>Name</th>
<th>Referrer Id</th>
<th>Mobile No.</th>
<th>Email Id</th>
<th>Password</th>
<th>State</th>
<th>City</th>
<th>Balance</th>
<th>Affiliate Balance New</th>
<th>Status</th>
<th>Action</th>
</tr>

const tableRow= <tr>
<td><span className='me-1'>7075195085</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>Gadasanda - Akshadha</td>
<td> 8788941394 Sharvari Mangaonkar</td>
<td><span className='me-1'>9582365425</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td> 
<td>prakashprakash52822@gmail.com</td>
<td>***********</td>
<td>Andra Pradesh</td>
<td>Rajkot</td>
<td>0.00</td>
<td>0.00</td>
<td class="text-success"> Active</td>
<td><ActionDropdown updateTitle={"Update User"} updateForm={updateForm} options={["Active","Deactive"]} hasClass={true}/>
</td>
</tr>
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
      <PageTitle title={"User"}/>
      <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>

    </div>

  </main>
  )
}

export default ManageUser