import React from 'react'
import { PageTitle } from '../components'

const Notification = () => {
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
<PageTitle title={"Android Notification"}/>
      <div class="card mb-3 mb-lg-5">
        <div class="card-body">
          
          <div class="col-sm-6 mb-4">
            <label for="notificationtitle" class="form-label">Enter Title</label>
            <input type="text" class="form-control mb-2" name="firstNameDeliveryAddress" id="notificationtitle" placeholder="Enter Title" aria-label="name"/>
          </div>

          <div class="col-sm-6 tom-select-custom mb-3">
            <label class="form-label">Select Notification Topic</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Select Notification Topic",
                    "hideSearch": true
                  }'>
              <option value="">Select Notification Topic</option>
              <option value="1">Notification Topic 1</option>
              <option value="2">Notification Topic 2</option>
            </select>
          </div>

          <div class="col-sm-6 mb-3">
            <label class="form-label" for="notificationtextarea">Message</label>
            <textarea id="notificationtextarea" class="form-control" placeholder="Enter message" rows="3"></textarea>
          </div>

          <div class="col-sm-6 mb-4">
            <label for="notificationlink" class="form-label">Enter Link</label>
            <input type="text" class="form-control mb-2" name="firstNameDeliveryAddress" id="notificationlink" placeholder="Enter Link" aria-label="name"/>
          </div>

          <p class="col-sm-6 text-secondary fw-bold mb-3">OR</p>

          <div class="col-sm-6 tom-select-custom mb-3">
            <label class="form-label">Select Service</label>
            <select class="js-select form-select" autocomplete="off"
                  data-hs-tom-select-options='{
                    "placeholder": "Select Service",
                    "hideSearch": true
                  }'>
              <option value="">Select Notification Topic</option>
              <option value="1">Service 1</option>
              <option value="2">Service 2</option>
            </select>
          </div>

          <div class="col-sm-6 mb-3">
            <label for="basicFormFile" class="js-file-attach form-label"
            data-hs-file-attach-options='{
              "textTarget": "[for=\"customFile\"]"
            }'>File</label>
            <input class="form-control mb-2" type="file" id="basicFormFile"/>
            <span class="text-success fs-6">Size 120*120 this image displays in notification</span>
          </div>
            
          <div class="col-auto mt-4">
            <button type="button" class="btn btn-primary me-3">SUBMIT</button>
            <button type="button" class="btn btn-outline-secondary">CLEAR</button>
          </div>
        </div>
      </div>

    </div>
    
  </main>
  )
}

export default Notification