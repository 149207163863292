import React, { useState } from 'react'
import { BrowserRouter, Route, Routes, useResolvedPath } from "react-router-dom";
import Navigation from '../components/layout/Header/Navigation';
import SignIn from '../pages/SignIn';
import { APISalesReport, Admin, BusBooking, BuySellReport, Commission, ConvenienceMaster, CouponMaster, FlightBooking, Gift, LiveRecharge, ManageUser, Notification, Operators, PaymentGatewayReport, PendingRecharge, PrimePoint, PrimePointReport, RechargeBrowsePlan, SMSReport, Service, ServiceReport, Services, ShoppingPoint, ShoppingPointReport, UpdateRechargeTransaction, UserDetails, VIPSMerchant, VIPSWallet, VIPSWalletReport } from '../pages';

const Router = () => {
  const [isNavigation, setIsNavigation] = useState(true)

  return (
    <div className={isNavigation ? 'has-navbar-vertical-aside navbar-vertical-aside-show-xl   footer-offset':""}>
    <BrowserRouter>
    {isNavigation && < Navigation />}
  
    <Routes>
<Route path='/' element={<SignIn setIsNavigation={setIsNavigation}/>}/>

<Route path='/master/services' element={<Services/>}/>
<Route path='/master/operators' element={<Operators/>}/>
<Route path='/master/Commission' element={<Commission/>}/>

<Route path='/coupon-master' element={<CouponMaster/>}/>
<Route path='/convenience-master' element={<ConvenienceMaster/>}/>
<Route path='/manage-user' element={<ManageUser/>}/>

<Route path='/wallet/vips-wallet' element={<VIPSWallet/>}/>
<Route path='/wallet/shopping-point' element={<ShoppingPoint/>}/>
<Route path='/wallet/prime-point' element={<PrimePoint/>}/>
<Route path='/wallet/vips-merchant' element={<VIPSMerchant/>}/>

<Route path='/vips-gold/buy-sell-report' element={<BuySellReport/>}/>
<Route path='/vips-gold/gift' element={<Gift/>}/>
<Route path='/vips-gold/user-details' element={<UserDetails/>}/>

<Route path='/report/service-report' element={<ServiceReport/>}/>
<Route path='/report/live-recharge-report' element={<LiveRecharge/>}/>
<Route path='/report/api-sales-report' element={<APISalesReport/>}/>
<Route path='/report/pending-recharge' element={<PendingRecharge/>}/>
<Route path='/report/update-recharge' element={<UpdateRechargeTransaction/>}/>
<Route path='/report/sms-report' element={<SMSReport/>}/>
<Route path='/report/vips-wallet-report' element={<VIPSWalletReport/>}/>
<Route path='/report/shopping-point-report' element={<ShoppingPointReport/>}/>
<Route path='/report/prime-point-report' element={<PrimePointReport/>}/>
<Route path='/report/payment-gateway-report' element={<PaymentGatewayReport/>}/>
<Route path='/report/bus-booking-report' element={<BusBooking/>}/>
<Route path='/report/flight-booking-report' element={<FlightBooking/>}/>

<Route path='/api-forward/services' element={<Service/>}/>
<Route path='/api-forward/recharge-browse-plan' element={<RechargeBrowsePlan/>}/>

<Route path='/notification' element={<Notification/>}/>
<Route path='/admin' element={<Admin/>}/>
    </Routes>
    </BrowserRouter>
    </div>
  
  )
}

export default Router