import React from 'react'
import { CreditDebitForm, DataTable } from '../../components'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import { IoCalendarOutline, IoRefreshSharp } from "react-icons/io5";

const VIPSWallet = () => {

const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
              
<div class="mb-2 mb-sm-0">
    <form>
      <div class="input-group">
        {/* <div class="tom-select-custom text-secondary">
        <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Services
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </div> */}
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>
    </form>
</div>

<div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
  
  <div class="col-sm-auto">
      <div class="input-group">
          <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
      
          <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
          <IoCalendarOutline size={"1rem"}/>
          </div>
      </div>
  </div>
  
  <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
  </div>

  <div class="col-sm-auto">
    <button type="button" class="btn btn-outline-secondary"><IoRefreshSharp size={"1rem"}/> Refresh</button>
</div>
  
</div>
</div>

const tableHead=<tr>
<th>Sr. No.</th>
<th>Credit User</th>
<th>Type</th>
<th>Amount</th>
<th>Date</th>
<th>Remarks</th>
</tr>

const tableRow=  <tr>
<td>8768348508 -79a8d98</td>
<td>Add Money</td>
<td>70555.52</td>
<td>4/29/2023 12:03:59 PM</td> 
<td class="w-25 text-wrap">Add money from PAYUMONEY to:9491663767|1bde5b26afe5315b1bd6|100.00</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
  {/* <PageTitle title={"VIPS Wallet"}/> */}

<CreditDebitForm title={"Wallet"}/>

      <p class="text-secondary fs-2 fw-bold">Recent Transactions</p>

      <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow} />

    </div>
  </main>
  )
}

export default VIPSWallet