import React from 'react'

const CreditDebitForm = ({title}) => {
  return (
    <>
     <ul class="nav nav-pills mb-5" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="nav-one-eg2-tab" href="#nav-one-eg2" data-bs-toggle="pill" data-bs-target="#nav-one-eg2" role="tab" aria-controls="nav-one-eg2" aria-selected="true">
            <div class="d-flex align-items-center">
                Credit {title}
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn-outline-dark" id="nav-two-eg2-tab" href="#nav-two-eg2" data-bs-toggle="pill" data-bs-target="#nav-two-eg2" role="tab" aria-controls="nav-two-eg2" aria-selected="false">
            <div class="d-flex align-items-center">
                Debit {title}
            </div>
          </a>
        </li>
        
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade show active" id="nav-one-eg2" role="tabpanel" aria-labelledby="nav-one-eg2-tab">
          <p class="fs-3 fw-bold text-primary text-end mt-n9 mb-5">Total {title}:  15,45,94,225 </p>
          <div class="card mb-3 mb-lg-5">
            <div class="card-body">
              
                <div class="col-sm-6 mb-4">
                  <label for="walletcreditUsername" class="form-label">Username *</label>
                  <input type="text" class="form-control mb-2" name="firstNameDeliveryAddress" id="walletcreditUsername" placeholder="Enter username" aria-label="name"/>
                  <span class="text-primary"> <label>Name: Office Software</label> | <label>Current Balance: 3,352.12</label> </span>
                </div>

                <div class="col-sm-6 mb-4"> 
                  <label for="walletcreditAmount" class="form-label">Credit Amount *</label>
                  <input type="text" class="form-control" name="lastNameDeliveryAddress" id="walletcreditAmount" placeholder="Enter amount" aria-label="amount"/> 
                </div>

                <div class="col-md-6 mb-4">
                  <label class="form-label" for="FormControlRemark">Remark *</label>
                  <textarea id="FormControlRemark" class="form-control" placeholder="Enter comments" rows="4"></textarea>
                </div>
                
                <div class="col-auto mt-4">
                  <button type="button" class="btn btn-primary me-3">CREDIT</button>
                  <button type="button" class="btn btn-outline-secondary">CLEAR</button>
                </div>
            </div>
          </div>

          
          
        </div>
        
      </div>
    </>
  )
}

export default CreditDebitForm