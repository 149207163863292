import React from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import { IoCalendarOutline,  } from "react-icons/io5";

const Gift = () => {

  const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">

        <div class="input-group">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Username
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>
    
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
    
    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
            </div>
        </div>
    </div>
    
    <div class="col-sm-auto ">
        <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead=<tr>
<th>Sr. No.</th>
<th>Register Date</th>
<th>Sender Name</th>
<th>Sender Unique Id</th>
<th>Sender Mobile Number</th>
<th>Sender Gold Balance</th>
<th>Sender Silver Balance</th>
<th>Receiver Name</th>
<th>Receiver Unique Id</th>
<th>Receiver Mobile Number</th>
<th>Receiver Gold Balance</th>
<th>Receiver Silver Balance</th>
<th>Metal Type</th>
<th>Quantity</th>
<th>Transaction Id</th>
<th>Merchant Transaction Id</th>
<th>App Type</th>
<th>Current App Version</th>
</tr>

const tableRow=  <tr>
<td>4/29/2023 12:03:59 PM</td>
<td>Sonika Kumari</td>
<td>15752532</td>
<td>9652536253</td>
<td>0</td>
<td>1.0592</td>
<td>Pratiksha Muley</td>
<td>1271027</td>
<td>7028081009</td>
<td>0</td>
<td>0.1173</td>
<td>Silver</td>
<td>0.0137</td>
<td>VW567316826006947258936325</td>
<td>VIPS20230427063636233</td>
<td>Website</td>
<td>10.28.0</td>
</tr>
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
    <PageTitle title={"Gift"}/>
    <DataTable  header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

    
  </main>
  )
}

export default Gift