import React, { useState } from "react";
import {
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ActionDropdown, DataTable, PageTitle } from "../../components";
import { BsSearch } from "react-icons/bs";

const Services = () => {
  const cardHeader = () => (
    <div class="card-header border-0 card-header-content-sm-between">
      <div class="mb-2 mb-sm-0">
        <div class="input-group mb-2">
          <div class="tom-select-custom text-secondary">
            <Dropdown>
              <Dropdown.Toggle className="btn btn-light border border-1 filter-btn">
                Services
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <input
            type="text"
            class="form-control border-end-0 bg-light"
            name="fullName"
            placeholder="Search Services"
            aria-label="Search name or emails"
            aria-describedby="fullName"
          />

          <button class="btn btn-white border-start-0 bg-light" type="button">
            <BsSearch/>
            {/* <i class="bi bi-search"></i> */}
          </button>
        </div>
        {/* </div> */}
      </div>

      <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
        <div class="col-sm-auto border border-1 bg-light ps-3 rounded">
          <div class="row align-items-center gx-0">
            <div class="col">
              <span className="text-body me-2">Status:</span>
            </div>

            <div class="col-auto">
              <div class="tom-select-custom tom-select-custom-end">
                <Dropdown>
                  <Dropdown.Toggle className="btn btn-light filter-btn">
                    All
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Active</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Deactive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const updateForm = () => (
    <form>
      <div class="mb-3">
        <label class="form-label" for="exampleFormControlInput1">
          Service
        </label>
        <input
          type="text"
          id="exampleFormControlInput1"
          class="form-control"
          placeholder="Affiliate Shopping"
        />
      </div>

      <div class="mb-3">
        <label class="form-label" for="exampleFormControlInput1">
          Shopping Percentage
        </label>
        <input
          type="text"
          id="exampleFormControlInput1"
          class="form-control"
          placeholder="0.00"
        />
      </div>

      <div class="mb-3">
        <label class="form-label" for="exampleFormControlInput1">
          Prime Percentage
        </label>
        <input
          type="text"
          id="exampleFormControlInput1"
          class="form-control"
          placeholder="0.00"
        />
      </div>

      <div class="mb-3">
        <label class="form-label" for="exampleFormControlInput1">
          Repurchase Percentage
        </label>
        <input
          type="text"
          id="exampleFormControlInput1"
          class="form-control"
          placeholder="0.00"
        />
      </div>

      <div class="tom-select-custom mb-3">
        <label class="form-label">Status</label>
        <Dropdown>
          <Dropdown.Toggle className="btn btn-light border border-1 filter-btn w-100 bg-white">
            Active
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100">
            <Dropdown.Item>Active</Dropdown.Item>
            <Dropdown.Item>Deactive</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <select class="js-select form-select" autocomplete="off"
         data-hs-tom-select-options='{
           "placeholder": "Status...",
           "hideSearch": true
         }'>
     <option value="4">Active</option>
     <option value="1">Deactive</option>
   </select> */}
      </div>

      <div class="tom-select-custom">
        <label class="form-label">Coupon Enable Status</label>
        <Dropdown>
          <Dropdown.Toggle className="btn btn-light border border-1 filter-btn w-100 bg-white">
            Deactive
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100">
            <Dropdown.Item>Deactive</Dropdown.Item>
            <Dropdown.Item>Demo 1</Dropdown.Item>
            <Dropdown.Item>Demo 2</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <select class="js-select form-select" autocomplete="off"
         data-hs-tom-select-options='{
           "placeholder": "Coupon Enable Status...",
           "hideSearch": true
         }'>
     <option value="4">Deactive</option>
     <option value="1">demo 1</option>
     <option value="3">demo 2</option>
   </select> */}
      </div>

      <div class="col-auto mt-4">
        <button type="button" class="btn btn-primary me-3">
          UPDATE
        </button>
        <button type="button" class="btn btn-outline-secondary">
          RESET
        </button>
      </div>
    </form>
  );

  const tableHead = (
    <tr>
      <th> Sr. No. </th>
      <th>Service</th>
      <th>Shopping Point Debit</th>
      <th>Repurchase %</th>
      <th>Shopping Point Credit</th>
      <th>Prime Point %</th>
      <th>Status</th>
      <th>Action</th>
    </tr>
  );

  const tableRow = (
    <tr>
      <td class=" ">Affiliate Shopping</td>
      <td> 0.00 </td>
      <td> 0 </td>
      <td>0.00</td>
      <td>25.00</td>
      <td class="text-success"> Active</td>
      <td>
        {" "}
        <ActionDropdown
          updateTitle={"Edit Services"}
          updateForm={updateForm}
          options={["Active", "Deactive"]}
        />{" "}
      </td>
    </tr>
  );

  return (
    <main id="content" role="main" class="main">
      <div class="content container-fluid">
        <PageTitle title={"Services"} />
        <DataTable
          header={cardHeader}
          tableHead={tableHead}
          tableRow={tableRow}
        />
      </div>
    </main>
  );
};

export default Services;
