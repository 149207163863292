import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SlEye } from "react-icons/sl";

const SignIn = ({setIsNavigation}) => {
 const navigate=  useNavigate()
    useEffect(() => {
     setIsNavigation(false)
      return ()=>{
        setIsNavigation(true)
      }
    }, [])
    
  return (
    <main id="content" role="main" class="main">
    
    <div class="container py-5 py-sm-7">
      

      <div class="offset-2 col-5" >

      <div class="text-center mb-5">
        <a class="" href="./index.html">
          <img class="" src="./assets/img/logos/Vipslogo.png" alt="Image Description" style={{"width": "14rem"}}/>
        </a>
      </div>

        <div class="card card-lg mb-5">
          <div class="card-body">
            <form class="js-validate needs-validation" novalidate>
              <div class="text-center">
                <div class="mb-5">
                  <h1 class="display-5 text-secondary">Sign In</h1>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida dignissim scelerisq </p> */}
                </div>
              </div>

              <div class="mb-4">
                <label class="form-label" for="signinSrEmail">Your Email</label>
                <input type="email" class="form-control form-control-lg" name="email" id="signinSrEmail" tabindex="1" placeholder="email@address.com" aria-label="email@address.com" required/>
                <span class="invalid-feedback">Please enter a valid email address.</span>
              </div>
              
              <div class="mb-4">
                <label class="form-label" for="signupSrPassword" tabindex="0"> Password </label>

                <div class="input-group input-group-merge" data-hs-validation-validate-class>
                  <input type="password" class="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required minlength="8" data-hs-toggle-password-options='{
                           "target": "#changePassTarget",
                           "defaultClass": "bi-eye-slash",
                           "showClass": "bi-eye",
                           "classChangeTarget": "#changePassIcon"
                         }'/>
                  <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;">
                         <SlEye/>
                  </a>
                </div>

                <span class="invalid-feedback">Please enter a valid password.</span>
              </div>

              <div class="mb-4">
                <label class="form-label" for="googleAuthentication ">Google Authentication</label>
                <input type="text" id="googleAuthentication" class="form-control form-control-lg" placeholder="00-00-00"/>
                <span class="invalid-feedback">Please enter a valid google authentication code.</span>
              </div>

              <div class="d-grid">
                <button type="submit" class="btn btn-primary btn-lg" onClick={()=>{navigate("/master/services")}}>Sign In</button>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
  </main>
  )
}

export default SignIn