import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { DataTable, PageTitle } from '../../components'
import { BsSearch } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const SMSReport = () => {

  const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">
        <div class="input-group">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>User </Dropdown.Toggle>
<Dropdown.Menu>
<Dropdown.Item href="#/action-1">Services</Dropdown.Item>
<Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
<Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search User ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
    
    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={'1rem'}/>
            </div>
        </div>
    </div>
    
    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead= <tr>
<th>Sr. No</th>
<th>User</th>
<th>Message</th>
<th>Req Time</th>
<th>Res Time</th>
<th>Message Response</th>
</tr>

const tableRow=<tr>
<td>8884677232</td>
<td class="text-wrap">
  <div class="input-group input-group-xl input-group-merge table-input-group border-0 w-100 text-wrap">
    <input id="copyusername" type="text" class="form-control pr-0 " readonly value="Thank you for contacting with us. Your User Name is 5622252252 and Password is demo@123. Kindly visit: www.demo.com. For queries Contact – 5862252225"/>
    <a class="js-clipboard input-group-append input-group-text" href="javascript:;" data-bs-toggle="tooltip" title="Copy to clipboard" data-hs-clipboard-options='{
        "type": "tooltip",
        "successText": "Copied!",
        "contentTarget": "#copyusername",
        "classChangeTarget": "#copyusernameIcon",
        "defaultClass": "bi-clipboard",
        "successClass": "bi-check"
       }'>
      <RiFileCopyLine size={"1rem"}/>
    </a>
  </div>
</td>
<td>4/29/2023 12:03:59 PM</td>
<td>4/29/2023 12:03:59 PM</td>
<td>OK:3614060799</td>
</tr>
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
   <PageTitle title={"SMS Report"}/>
   <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

  </main>
  )
}

export default SMSReport