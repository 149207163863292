import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Accordion, Button, Collapse, Dropdown } from "react-bootstrap";
import { AiFillApi, AiFillGold, AiFillHome } from "react-icons/ai";
import { TbHandClick } from "react-icons/tb";
import { HiReceiptPercent } from "react-icons/hi2";
import { FaHandHoldingUsd, FaUserCog } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import {  IoNotifications } from "react-icons/io5";
import { MdAccountBalanceWallet } from "react-icons/md";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { BiLogOutCircle} from "react-icons/bi";
import { useResolvedPath } from "react-router-dom";

const Navigation = () => {
  const path = useResolvedPath().pathname;

  const masterPath=path === "/master/services"||path ==="/master/operators"||path ==="/master/Commission"

  const walletPath=path === "/wallet/vips-wallet"||path ==="/wallet/shopping-point"||path ==="/wallet/prime-point"||path ==="/wallet/vips-merchant"

  const VIPSGold=path === "/vips-gold/buy-sell-report"||path ==="/vips-gold/gift"||path ==="/vips-gold/user-details"

  const reportPath=path === "/report/service-report"||path ==="/report/live-recharge-report"||path ==="/report/api-sales-report"||path ==="/report/pending-recharge"||path ==="/report/update-recharge"||path ==="/report/sms-report"||path ==="/report/vips-wallet-report"||path ==="/report/shopping-point-report"||path ==="/report/prime-point-report"||path ==="/report/payment-gateway-report"||path ==="/report/bus-booking-report"||path ==="/report/flight-booking-report"

  const APIForwardPath=path === "/api-forward/services"||path ==="/api-forward/recharge-browse-plan"

  const [dashboardOpen, setDashboardOpen] = useState(false);
  const [masterOpen, setMasterOpen] = useState(masterPath);
  const [walletOpen, setWalletOpen] = useState(false);
  const [VIPSGoldOpen, setVIPSGoldOpen] = useState(false);
  const [ReportOpen, setReportOpen] = useState(false);
  const [APIForwardOpen, setAPIForwardOpen] = useState(false);

  const handleSideBarToggle = () => {
    let target = document.body;
    if (!target.classList.contains("navbar-vertical-aside-mini-mode")) {
      target.classList.add("navbar-vertical-aside-mini-mode");
    } else {
      target.classList.remove("navbar-vertical-aside-mini-mode");
    }
  };



  const activeStyle=(currentPath)=>{
   return {
    color:path===currentPath && "#2C27CC",
    fontWeight:path===currentPath && "bold"
   } 
  }
  return (
    <>
      <header
        id="header"
        class="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white"
      >
        <div class="navbar-nav-wrap">
          <Link class="navbar-brand" to="/" aria-label="Front">
            <img
              class="navbar-brand-logo"
              src="/assets/img/logos/Vipslogo.png"
              alt="Logo"
              data-hs-theme-appearance="default"
            />
            <img
              class="navbar-brand-logo"
              src="/assets/img/logos/Vipslogo.png"
              alt="Logo"
              data-hs-theme-appearance="dark"
            />
            <img
              class="navbar-brand-logo-mini"
              src="/assets/img/logos/Vipslogo.png"
              alt="Logo"
              data-hs-theme-appearance="default"
            />
            <img
              class="navbar-brand-logo-mini"
              src="/assets/img/logos/Vipslogo.png"
              alt="Logo"
              data-hs-theme-appearance="dark"
            />
          </Link>

          <div class="navbar-nav-wrap-content-start">
            <button
              type="button"
              class="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
              onClick={handleSideBarToggle}
            >
              <i
                class="bi-arrow-bar-left navbar-toggler-short-align"
                data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Collapse"
              ></i>
              <i
                class="bi-arrow-bar-right navbar-toggler-full-align"
                data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Expand"
              ></i>
            </button>
          </div>

          <div class="navbar-nav-wrap-content-end">
            <ul class="navbar-nav">
              <li class="nav-item d-none d-sm-inline-block nfn-dropdown">
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle
                    className="btn btn-ghost-secondary btn-secondary btn-icon rounded-circle border-0"
                    id="dropdown-basic"
                    data-bs-auto-close="outside"
                    data-bs-dropdown-animation
                  >
                    <i className="bi-bell"></i>
                    <span className="btn-status btn-sm-status btn-status-danger"></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless"
                    style={{ width: "25rem" }}
                  >
                    <div class="card">
                      <div class="card-header card-header-content-between">
                        <h4 class="card-title mb-0">Notifications</h4>
                        <div class="dropdown">
                          <button
                            type="button"
                            class="btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                            id="navbarNotificationsDropdownSettings"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="bi-three-dots-vertical"></i>
                          </button>

                          <div
                            class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless"
                            aria-labelledby="navbarNotificationsDropdownSettings"
                          >
                            <span class="dropdown-header">Settings</span>
                            <a class="dropdown-item" href="#">
                              <i class="bi-archive dropdown-item-icon"></i>{" "}
                              Archive all
                            </a>
                            <a class="dropdown-item" href="#">
                              <i class="bi-check2-all dropdown-item-icon"></i>{" "}
                              Mark all as read
                            </a>
                            <a class="dropdown-item" href="#">
                              <i class="bi-toggle-off dropdown-item-icon"></i>{" "}
                              Disable notifications
                            </a>
                            <a class="dropdown-item" href="#">
                              <i class="bi-gift dropdown-item-icon"></i> What's
                              new?
                            </a>
                            <div class="dropdown-divider"></div>
                            <span class="dropdown-header">Feedback</span>
                            <a class="dropdown-item" href="#">
                              <i class="bi-chat-left-dots dropdown-item-icon"></i>{" "}
                              Report
                            </a>
                          </div>
                        </div>
                      </div>
                      <ul
                        class="nav nav-tabs nav-justified"
                        id="notificationTab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            href="#notificationNavOne"
                            id="notificationNavOne-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#notificationNavOne"
                            role="tab"
                            aria-controls="notificationNavOne"
                            aria-selected="true"
                          >
                            Messages (3)
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            href="#notificationNavTwo"
                            id="notificationNavTwo-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#notificationNavTwo"
                            role="tab"
                            aria-controls="notificationNavTwo"
                            aria-selected="false"
                          >
                            Archived
                          </a>
                        </li>
                      </ul>
                      <div class="card-body-height">
                        <div class="tab-content" id="notificationTabContent">
                          <div
                            class="tab-pane fade show active"
                            id="notificationNavOne"
                            role="tabpanel"
                            aria-labelledby="notificationNavOne-tab"
                          >
                            <ul class="list-group list-group-flush navbar-card-list-group">
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck1"
                                          checked
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck1"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <img
                                        class="avatar avatar-sm avatar-circle"
                                        src="/assets/img/160x160/img3.jpg"
                                        alt="Image Description"
                                      />
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Brian Warner</h5>
                                    <p class="text-body fs-5">
                                      changed an issue from "In Progress" to{" "}
                                      <span class="badge bg-success">
                                        Review
                                      </span>
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    2hr
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck2"
                                          checked
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck2"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-soft-dark avatar-circle">
                                        <span class="avatar-initials">K</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Klara Hampton</h5>
                                    <p class="text-body fs-5">
                                      mentioned you in a comment
                                    </p>
                                    <blockquote class="blockquote blockquote-sm">
                                      Nice work, love! You really nailed it.
                                      Keep it up!
                                    </blockquote>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    10hr
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck3"
                                          checked
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck3"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-circle">
                                        <img
                                          class="avatar-img"
                                          src="/assets/img/160x160/img10.jpg"
                                          alt="Image Description"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Ruby Walter</h5>
                                    <p class="text-body fs-5">
                                      joined the Slack group HS Team
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    3dy
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck4"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck4"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-circle">
                                        <img
                                          class="avatar-img"
                                          src="/assets/svg/brands/google-icon.svg"
                                          alt="Image Description"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">from Google</h5>
                                    <p class="text-body fs-5">
                                      Start using forms to capture the
                                      information of prospects visiting your
                                      Google website
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    17dy
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck5"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck5"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-circle">
                                        <img
                                          class="avatar-img"
                                          src="/assets/img/160x160/img7.jpg"
                                          alt="Image Description"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Sara Villar</h5>
                                    <p class="text-body fs-5">
                                      completed{" "}
                                      <i class="bi-journal-bookmark-fill text-primary"></i>{" "}
                                      FD-7 task
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    2mn
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                            </ul>
                          </div>

                          <div
                            class="tab-pane fade"
                            id="notificationNavTwo"
                            role="tabpanel"
                            aria-labelledby="notificationNavTwo-tab"
                          >
                            <ul class="list-group list-group-flush navbar-card-list-group">
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck6"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck6"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-soft-dark avatar-circle">
                                        <span class="avatar-initials">A</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Anne Richard</h5>
                                    <p class="text-body fs-5">
                                      accepted your invitation to join Notion
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    1dy
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck7"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck7"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-circle">
                                        <img
                                          class="avatar-img"
                                          src="./assets/img/160x160/img5.jpg"
                                          alt="Image Description"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Finch Hoot</h5>
                                    <p class="text-body fs-5">
                                      left Slack group HS projects
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    1dy
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck8"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck8"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-dark avatar-circle">
                                        <span class="avatar-initials">HS</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Htmlstream</h5>
                                    <p class="text-body fs-5">
                                      you earned a "Top endorsed"{" "}
                                      <i class="bi-patch-check-fill text-primary"></i>{" "}
                                      badge
                                    </p>
                                  </div>
                                  <small class="col-auto text-muted text-cap">
                                    6dy
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>

                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck9"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck9"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-circle">
                                        <img
                                          class="avatar-img"
                                          src="./assets/img/160x160/img8.jpg"
                                          alt="Image Description"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Linda Bates</h5>
                                    <p class="text-body fs-5">
                                      Accepted your connection
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    17dy
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                              <li class="list-group-item form-check-select">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notificationCheck10"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="notificationCheck10"
                                        ></label>
                                        <span class="form-check-stretched-bg"></span>
                                      </div>
                                      <div class="avatar avatar-sm avatar-soft-dark avatar-circle">
                                        <span class="avatar-initials">L</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col ms-n2">
                                    <h5 class="mb-1">Lewis Clarke</h5>
                                    <p class="text-body fs-5">
                                      completed{" "}
                                      <i class="bi-journal-bookmark-fill text-primary"></i>{" "}
                                      FD-134 task
                                    </p>
                                  </div>

                                  <small class="col-auto text-muted text-cap">
                                    2mts
                                  </small>
                                </div>

                                <a class="stretched-link" href="#"></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <a class="card-footer text-center" href="#">
                        View all notifications <i class="bi-chevron-right"></i>
                      </a>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              <li class="nav-item">
                <div class="dropdown">
                  <a
                    class="navbar-dropdown-account-wrapper"
                    href="javascript:;"
                    id="accountNavbarDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    data-bs-dropdown-animation
                  >
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-sm avatar-circle">
                        <img
                          class="avatar-img"
                          src="../assets/img/160x160/user_avatar.svg"
                          alt="Image Description"
                        />
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <h5 class="mb-0">Ramprasad Kadadm</h5>
                        <p class="card-text text-body">Super Admin</p>
                      </div>
                    </div>
                  </a>

                  <div
                    class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                    aria-labelledby="accountNavbarDropdown"
                    style={{ width: "16rem" }}
                  >
                    <div class="dropdown">
                      <a
                        class="navbar-dropdown-submenu-item dropdown-item dropdown-toggle"
                        href="javascript:;"
                        id="navSubmenuPagesAccountDropdown1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Set status
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu"
                        aria-labelledby="navSubmenuPagesAccountDropdown1"
                      >
                        <a class="dropdown-item" href="#">
                          <span class="legend-indicator bg-success me-1"></span>{" "}
                          Available
                        </a>
                        <a class="dropdown-item" href="#">
                          <span class="legend-indicator bg-danger me-1"></span>{" "}
                          Busy
                        </a>
                        <a class="dropdown-item" href="#">
                          <span class="legend-indicator bg-warning me-1"></span>{" "}
                          Away
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">
                          {" "}
                          Reset status
                        </a>
                      </div>
                    </div>

                    <a class="dropdown-item" href="#">
                      Profile &amp; account
                    </a>
                    <a class="dropdown-item" href="#">
                      Settings
                    </a>

                    <div class="dropdown-divider"></div>

                    <a class="dropdown-item" href="#">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <div class="avatar avatar-sm avatar-dark avatar-circle">
                            <span class="avatar-initials">HS</span>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h5 class="mb-0">
                            Htmlstream{" "}
                            <span class="badge bg-primary rounded-pill text-uppercase ms-1">
                              PRO
                            </span>
                          </h5>
                          <span class="card-text">hs.example.com</span>
                        </div>
                      </div>
                    </a>

                    <div class="dropdown-divider"></div>

                    <div class="dropdown">
                      <a
                        class="navbar-dropdown-submenu-item dropdown-item dropdown-toggle"
                        href="javascript:;"
                        id="navSubmenuPagesAccountDropdown2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Customization
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu"
                        aria-labelledby="navSubmenuPagesAccountDropdown2"
                      >
                        <a class="dropdown-item" href="#">
                          Invite people
                        </a>
                        <a class="dropdown-item" href="#">
                          Analytics
                          <i class="bi-box-arrow-in-up-right"></i>
                        </a>
                        <a class="dropdown-item" href="#">
                          Customize Front
                          <i class="bi-box-arrow-in-up-right"></i>
                        </a>
                      </div>
                    </div>

                    <a class="dropdown-item" href="#">
                      Manage team
                    </a>

                    <div class="dropdown-divider"></div>

                    <a class="dropdown-item" href="#">
                      Sign out
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <aside class="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white ">
        <div class="navbar-vertical-container">
          <div class="navbar-vertical-footer-offset">
            <Link class="navbar-brand" to="/" aria-label="Front">
              <img
                class="navbar-brand-logo"
                src="/assets/img/logos/Vipslogo.png"
                alt="Logo"
              />
              <img
                class="navbar-brand-logo-mini"
                src="/assets/img/logos/Vipslogo.png"
                alt="Logo"
                data-hs-theme-appearance="default"
              />
            </Link>

            <button
              type="button"
              class="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
              onClick={handleSideBarToggle}
            >
              <i
                class="bi-arrow-bar-left navbar-toggler-short-align"
                data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Collapse"
              ></i>
              <i
                class="bi-arrow-bar-right navbar-toggler-full-align"
                data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Expand"
              ></i>
            </button>

            <div class="navbar-vertical-content">
              <div
                id="navbarVerticalMenu"
                class="nav nav-pills nav-vertical card-navbar-nav"
              >
                <div class="nav-item">
                  <Link
                    className="nav-link dropdown-toggle"
                    onClick={() => setDashboardOpen(!dashboardOpen)}
                    aria-controls="dashboards"
                    aria-expanded={dashboardOpen}
                  >
                    <i className="nav-icon">
                      {" "}
                      <AiFillHome size={"1.1rem"}/>
                    </i>
                    <span class="nav-link-title">Dashboard</span>
                  </Link>
                  <Collapse
                    className="nav-collapse collapse"
                    in={dashboardOpen}
                  >
                    <div id="dashboards" class="">
                      <Link class="nav-link " href="#">
                        User Analytics
                      </Link>
                      <Link class="nav-link " href="#">
                        Services
                      </Link>
                      <Link class="nav-link " href="#">
                        Earnings
                      </Link>
                    </div>
                  </Collapse>
                </div>

                <small class="bi-three-dots nav-subtitle-replacer"></small>

                <div class="navbar-nav nav-compact"></div>
                <Accordion id="navbarVerticalMenuPagesMenu">
                  <div class="nav-item">
                    <Link
                      className={masterPath? "nav-link dropdown-toggle active":"nav-link dropdown-toggle" }
                      onClick={() => setMasterOpen(!masterOpen)}
                      aria-controls="master"
                      aria-expanded={masterOpen}
                    >
                      <i className="nav-icon">
                        {" "}
                        <TbHandClick size={"1.1rem"}/>
                      </i>
                      {/* <i class="bi-people nav-icon"></i> */}
                      <span class="nav-link-title">Master</span>
                    </Link>
                    <Collapse
                      className={masterPath?"nav-collapse collapse show":"nav-collapse collapse"}
                      in={masterOpen}
                    >
                      <div id="master">
                        <Link class={"nav-link"} style={activeStyle("/master/services")} to="/master/services">
                          Services
                        </Link>
                        <Link class="nav-link" style={activeStyle("/master/operators")} to="/master/operators">
                          Operators
                        </Link>
                        <Link class="nav-link" style={activeStyle("/master/Commission")} to="/master/Commission">
                          Commission / API Setting
                        </Link>
                      </div>
                    </Collapse>
                  </div>

                  <div class="nav-item">
                    <Link
                      className="nav-link" style={activeStyle("/coupon-master")}
                      to="/coupon-master"
                      data-placement="left"
                    >
                      <i className="nav-icon">
                        <HiReceiptPercent size={"1.1rem"}/>{" "}
                      </i>
                      {/* <i class="bi-key nav-icon"></i> */}
                      <span class="nav-link-title">Coupon Master</span>
                    </Link>
                  </div>

                  <div class="nav-item">
                    <Link
                      class="nav-link" style={activeStyle("/convenience-master")}
                      to="/convenience-master"
                      data-placement="left"
                    >
                      {" "}
                      <i className="nav-icon">
                        <FaHandHoldingUsd size={"1.1rem"}/>{" "}
                      </i>
                      {/* <i class="bi-key nav-icon"></i> */}
                      <span class="nav-link-title">Convenience Master</span>
                    </Link>
                  </div>

                  <div class="nav-item">
                    <Link
                      class="nav-link "
                      to="/manage-user" style={activeStyle("/manage-user")}
                      data-placement="left"
                    >  <i className="nav-icon">
                    <IoIosPeople size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-key nav-icon"></i> */}
                      <span class="nav-link-title">Manage User</span>
                    </Link>
                  </div>

                  <div class="nav-item">
                    <Link
                      className={walletPath?"nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                      onClick={() => setWalletOpen(!walletOpen)}
                      aria-controls="wallet"
                      aria-expanded={walletOpen}
                    ><i className="nav-icon">
                    <MdAccountBalanceWallet size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-person nav-icon"></i> */}
                      <span class="nav-link-title">Wallet</span>
                    </Link>
                    <Collapse
                      className={walletPath?"nav-collapse collapse show":"nav-collapse collapse"}
                      in={walletOpen}
                      data-parent="#navbarVerticalMenuPagesMenu"
                    >
                      <div id="wallet">
                        <Link class="nav-link" style={activeStyle("/wallet/vips-wallet")} to="/wallet/vips-wallet">
                          VIPS Wallet
                        </Link>
                        <Link class="nav-link " to="/wallet/shopping-point" style={activeStyle("/wallet/shopping-point")} >
                          Shopping Point
                        </Link>
                        <Link class="nav-link " to="/wallet/prime-point" style={activeStyle("/wallet/prime-point")}>
                          Prime Point
                        </Link>
                        <Link class="nav-link " to="/wallet/vips-merchant" style={activeStyle("/wallet/vips-merchant")}>
                          VIPS Merchant
                        </Link>
                      </div>
                    </Collapse>
                  </div>
                  <div class="nav-item">
                    <Link
                        className={VIPSGold?"nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                      onClick={() => setVIPSGoldOpen(!VIPSGoldOpen)}
                      aria-controls="VIPSGold"
                      aria-expanded={VIPSGoldOpen}
                    ><i className="nav-icon">
                    <AiFillGold size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-person-badge nav-icon"></i> */}
                      <span class="nav-link-title">VIPS Gold</span>
                    </Link>
                    <Collapse
                    className={VIPSGold?"nav-collapse collapse show":"nav-collapse collapse"}
                      in={VIPSGoldOpen}
                      data-parent="#navbarVerticalMenuPagesMenu"
                    >
                      <div id="VIPSGold" class="">
                        <Link class="nav-link " to="/vips-gold/buy-sell-report" style={activeStyle("/vips-gold/buy-sell-report")}>
                          Buy/Sell Report
                        </Link>
                        <Link class="nav-link " to="/vips-gold/gift" style={activeStyle("/vips-gold/gift")}>
                          Gift
                        </Link>
                        <Link class="nav-link " to="/vips-gold/user-details" style={activeStyle("/vips-gold/user-details")}>
                          User Details
                        </Link>
                      </div>
                    </Collapse>
                  </div>

                  <div class="nav-item">
                    <Link
                      className={reportPath?"nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                      onClick={() => setReportOpen(!ReportOpen)}
                      aria-controls="report"
                      aria-expanded={ReportOpen}
                    ><i className="nav-icon">
                    <BsFileEarmarkTextFill size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-stickies nav-icon"></i> */}
                      <span class="nav-link-title">Report</span>
                    </Link>
                    <Collapse
                       className={reportPath?"nav-collapse collapse show":"nav-collapse collapse"}
                      in={ReportOpen}
                      data-parent="#navbarVerticalMenuPagesMenu"
                    >
                      <div id="report" class="">
                        <Link class="nav-link " to="/report/service-report" style={activeStyle("/report/service-report")}>
                          Service Report
                        </Link>
                        <Link
                          class="nav-link "
                          to="/report/live-recharge-report"
                          style={activeStyle("/report/live-recharge-report")}>
                          Live Recharge Report
                        </Link>
                        <Link class="nav-link " to="/report/api-sales-report"  style={activeStyle("/report/api-sales-report")}>
                          API Sales Report
                        </Link>
                        <Link class="nav-link " to="/report/pending-recharge" style={activeStyle("/report/pending-recharge")}>
                          Pending Recharge
                        </Link>
                        <Link class="nav-link " to="/report/update-recharge" style={activeStyle("/report/update-recharge")}>
                          Update Recharge Transaction
                        </Link>
                        <Link class="nav-link " to="/report/sms-report" style={activeStyle("/report/sms-report")}>
                          SMS Report
                        </Link>
                        <Link class="nav-link " to="/report/vips-wallet-report" style={activeStyle("/report/vips-wallet-report")}>
                          VIPS Wallet Report
                        </Link>
                        <Link
                          class="nav-link "
                          to="/report/shopping-point-report"
                          style={activeStyle("/report/shopping-point-report")}
                        >
                          Shopping Point Report
                        </Link>
                        <Link class="nav-link " to="/report/prime-point-report" style={activeStyle("/report/prime-point-report")}>
                          Prime Point Report
                        </Link>
                        <Link
                          class="nav-link "
                          to="/report/payment-gateway-report"
                          style={activeStyle("/report/payment-gateway-report")}>
                          Payment Gateway Transaction Report
                        </Link>
                        <Link class="nav-link " to="/report/bus-booking-report" style={activeStyle("/report/bus-booking-report")}>
                          Bus Booking Report
                        </Link>
                        <Link
                          class="nav-link "
                          to="/report/flight-booking-report"
                          style={activeStyle("/report/flight-booking-report")}
                        >
                          Flight Booking Report
                        </Link>
                      </div>
                    </Collapse>
                  </div>

                  <div class="nav-item">
                    <Link
                       className={APIForwardPath?"nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                      onClick={() => setAPIForwardOpen(!APIForwardOpen)}
                      aria-controls="APIForward"
                      aria-expanded={APIForwardOpen}
                    >
                      <i className="nav-icon">
                    <AiFillApi size={"1.1rem"}/>{" "}
                  </i>
                      
                      {/* <i class="bi-briefcase nav-icon"></i> */}
                      <span class="nav-link-title">API forward</span>
                    </Link>
                    <Collapse
                     className={APIForwardPath?"nav-collapse collapse show":"nav-collapse collapse"}
                      in={APIForwardOpen}
                      data-parent="#navbarVerticalMenuPagesMenu"
                    >
                      <div id="APIForward" class="">
                        <Link class="nav-link " to="/api-forward/services" style={activeStyle("/api-forward/services")}>
                          Services
                        </Link>
                        <Link
                          class="nav-link "
                          to="/api-forward/recharge-browse-plan" style={activeStyle("/api-forward/recharge-browse-plan")}
                        >
                          Recharge Browse Plan API
                        </Link>
                      </div>
                    </Collapse>
                  </div>

                  <div class="nav-item">
                    <Link
                      class="nav-link "
                      to="/notification"
                      data-placement="left"  style={activeStyle("/notification")}
                    > <i className="nav-icon">
                    <IoNotifications size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-key nav-icon"></i> */}
                      <span class="nav-link-title">Notification</span>
                    </Link>
                  </div>

                  <div class="nav-item">
                    <Link class="nav-link " to="/admin" data-placement="left" style={activeStyle("/admin")}>
                    
                    <i className="nav-icon">
                    <FaUserCog size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-eye nav-icon"></i> */}
                      <span class="nav-link-title">Admin</span>
                    </Link>
                  </div>
                  <div class="nav-item">
                    <Link class="nav-link " to="/" data-placement="left">
                    
                    <i className="nav-icon">
                    <BiLogOutCircle size={"1.1rem"}/>{" "}
                  </i>
                      {/* <i class="bi-eye nav-icon"></i> */}
                      <span class="nav-link-title">Logout</span>
                    </Link>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Navigation;
