import React, { useState } from "react";
import { DataTable, PageTitle } from "../../components";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import {  FiUpload , FiFilter,} from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const PaymentGatewayReport = () => {
  const [showFilter, setShowFilter] = useState(false);
  const cardHeader = () => (
    <div class="card-header border-0 card-header-content-sm-between">
      <div class="mb-2 mb-sm-0">
        <form>
          <div class="input-group">
            <div class="tom-select-custom text-secondary">
              <Dropdown>
                <Dropdown.Toggle className="btn btn-light border border-1 filter-btn">
                  Username{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <input
              type="text"
              class="form-control border-end-0 bg-light"
              name="fullName"
              placeholder="Search Username ..."
              aria-label="Search name or emails"
              aria-describedby="fullName"
            />

            <button class="btn btn-white border-start-0 bg-light" type="button">
              <BsSearch/>
            </button>
          </div>
        </form>
      </div>

      <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
        <div class="col-sm-auto">
          <div class="input-group">
            <input
              type="text"
              id="with-calendar-field"
              class="js-daterangepicker form-control daterangepicker-custom-input"
            />

            <div
              class="input-group-append input-group-text"
              onclick="$('#with-calendar-field').data('daterangepicker').toggle();"
            >
              <IoCalendarOutline size={"1rem"}/>
            </div>
          </div>
        </div>

        <div class="col-sm-auto border border-1 bg-light ps-3 rounded">
          <div class="row align-items-center gx-0">
            <div class="col">
              <span class="text-secondary me-2">Status:</span>
            </div>

            <div class="col-auto">
              <div class="tom-select-custom tom-select-custom-end">
              <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>All </Dropdown.Toggle>
<Dropdown.Menu>
<Dropdown.Item href="#/action-1">All</Dropdown.Item>
<Dropdown.Item href="#/action-2">Debit</Dropdown.Item>
<Dropdown.Item href="#/action-3">Credit</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-auto">
          <button
            type="button"
            class="btn btn-outline-secondary"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <FiFilter size={"1rem"}/> Filter
          </button>
        </div>
        <Offcanvas
          show={showFilter}
          onHide={() => {
            setShowFilter(false);
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <form>
              <div class="tom-select-custom mb-3">
                <label class="form-label">Search By</label>
                <select
                  class="js-select form-select"
                  autocomplete="off"
                  data-hs-tom-select-options='{
                      "placeholder": "Username",
                      "hideSearch": true
                    }'
                >
                  <option value="">Select</option>
                  <option value="1">Username</option>
                  <option value="2">Transaction Type</option>
                  <option value="2">Amount</option>
                </select>
              </div>

              <div class="mb-3">
                <label class="form-label" for="exampleFormControlInput1">
                  Search Username
                </label>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  class="form-control"
                  placeholder="Search by username"
                />
              </div>

              <div class="tom-select-custom mb-3">
                <label class="form-label">Select Transaction Type</label>
                <div class="tom-select-custom">
                  <select
                    class="js-select form-select"
                    autocomplete="off"
                    data-hs-tom-select-options='{
                            "placeholder": "Select Transaction Type",
                            "hideSearch": true
                          }'
                  >
                    <option value="">Select Transaction Type</option>
                    <option value="1">Kolhapur</option>
                    <option value="2">Pune</option>
                  </select>
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">Amount From</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">
                    &#x20b9;
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="1000"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">Amount To</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">
                    &#x20b9;
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="10,000"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div class="col-auto mt-4">
                <button type="button" class="btn btn-primary me-3">
                  SEARCH
                </button>
                <button type="button" class="btn btn-outline-secondary">
                  RESET
                </button>
              </div>
            </form>
          </Offcanvas.Body>
        </Offcanvas>

        <div class="col-sm-auto ">
          <button type="button" class="btn btn-outline-success">
            <FiUpload size={"1rem"}/> Export
          </button>
        </div>
      </div>
    </div>
  );

  const tableHead = (
    <tr>
      <th>Sr. No.</th>
      <th>User Name</th>
      <th>Transaction ID</th>
      <th>Amount</th>
      <th>Status</th>
      <th>Added Date</th>
      <th>Updated Date</th>
      <th>App Type</th>
      <th>Transaction Type</th>
      <th>Pg Name</th>
      <th>Payment Type</th>
      <th>Charges %</th>
      <th>Charges Amount</th>
      <th>Amount Without Charges</th>
      <th>Current App Version</th>
      <th>Action</th>
    </tr>
  );

  const tableRow = (
    <tr>
      <td><span className='me-1'>9522325625</span> <span className='copy-btn'> <RiFileCopyLine /></span>
      </td>
      <td><span className='me-1'>7043315d9d579a14534f</span> <span className='copy-btn'> <RiFileCopyLine /></span>
      </td>
      <td>1000</td>
      <td class="text-success"> Success</td>
      <td>4/29/2023 12:03:59 PM</td>
      <td>4/29/2023 12:03:59 PM</td>
      <td>Android</td>
      <td>Add_Money</td>
      <td>PAYUMONEY</td>
      <td>-</td>
      <td>0.00</td>
      <td>0.00</td>
      <td>1000</td>
      <td>10.27.0</td>
      <td>
        {" "}
        <button type="button" class="btn btn-secondary">
          {" "}
          Check{" "}
        </button>{" "}
      </td>
    </tr>
  );

  return (
    <main id="content" role="main" class="main">
      <div class="content container-fluid">
        <PageTitle title={"Payment Gateway Transaction Report"} />

        <div class="row mb-5">
          <div class="col-md-3">
            <div class="card card-bordered h-100">
              <div class="card-body">
                <h3 class="fs-5">Total Transaction Count</h3>
                <p class="fs-2 fw-bold text-secondary mb-0">15,455</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card card-bordered h-100">
              <div class="card-body">
                <h3 class="fs-5">Total Amount</h3>
                <p class="fs-2 fw-bold text-secondary mb-0">15,45,94,225</p>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          header={cardHeader}
          tableRow={tableRow}
          tableHead={tableHead}
        />
      </div>
    </main>
  );
};

export default PaymentGatewayReport;
