import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/css/theme.min.css"
import "./assets/vendor/tom-select/dist/css/tom-select.bootstrap5.css"
import "./assets/vendor/bootstrap-icons/font/bootstrap-icons.css"

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
