import React from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const APISalesReport = () => {

const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">
          
<div class="mb-2 mb-sm-0">
  
    <form>

      <div class="input-group">
        <div class="tom-select-custom text-secondary">
        <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
    API Code
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
        </div>
    
        <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search API Code ..." aria-label="Search name or emails" aria-describedby="fullName"/>
    
        <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
      </div>

    </form>
  
</div>

<div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
  
  <div class="col-sm-auto">
      <div class="input-group">
          <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
      
          <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
          <IoCalendarOutline size={"1rem"}/>
          </div>
      </div>
  </div>
  
  <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
  </div>

  <div class="col-sm-auto ">
    <button type="button" class="btn btn-outline-secondary"><RiFileCopyLine size={"1rem"}/> Copy</button>
  </div>
  
</div>
</div>

const tableHead=    <tr>
<th>Sr. No.</th>
<th>API Code</th>
<th>Total Sale</th>
<th>Commission (₹)</th>
<th>Commission (%)</th>
<th>Total Transaction Count</th>
<th>Success Transaction</th>
<th>Failure Transaction</th>
</tr>

const tableRow=   <tr>
<td>Money Art</td>
<td>653,972.00</td>
<td>1500</td>
<td>3%</td>
<td>653,972.00</td>
<td class="text-success">35.96</td>
<td class="text-danger">100,447.00</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
 <PageTitle title={"API Sales Report"}/>
 <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

    
  </main>
  )
}

export default APISalesReport