import React from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import { IoCalendarOutline, IoRefreshSharp } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const PendingRecharge = () => {

  const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">

        <div class="input-group">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
Username
</Dropdown.Toggle>

<Dropdown.Menu>
<Dropdown.Item>Services</Dropdown.Item>
<Dropdown.Item>Nikola</Dropdown.Item>
<Dropdown.Item>Nikola Tesla</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>
    
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
    
    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
            </div>
        </div>
    </div>
    
    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-secondary"><IoRefreshSharp size={"1rem"}/> Update All</button>
    </div>
    
    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead= <tr>
<th>Sr. No.
  {/* <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="datatableCheckAll"/>
    <label class="form-check-label text-secondary" for="datatableCheckAll">Sr. No.</label>
  </div> */}
  </th>
<th>Username</th>
<th>Number</th>
<th>Operator</th>
<th>Transaction Id</th>
<th>Amount</th>
<th>Bal Remaining</th>
<th>API</th>
<th>Status</th>
<th>Date</th>
<th>Action</th>
</tr>

const tableRow=    <tr>
{/* <td>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="usersDataCheck2"/>
    <label class="form-check-label" for="usersDataCheck2">1</label>
  </div>
  </td> */}
<td><span className='me-1'>9724771288</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td><span className='me-1'>7525232523</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>JIO-Recharge</td>
<td>J0131010612512AAEE9</td>
<td>2,510.00</td>
<td>210.00</td>
<td>MT</td>
<td class="text-warning">Pending</td>
<td>4/29/2023 12:03:59 PM</td>
<td>
<Dropdown className='action-dropdown'>
    <Dropdown.Toggle className="btn btn-secondary btn-sm"  id="dropdown-basic">Actions </Dropdown.Toggle>

    <Dropdown.Menu class=" dropdown-menu-end">
      <Dropdown.Item>Success with Debitnote</Dropdown.Item>
      <Dropdown.Item>Success</Dropdown.Item>
      <Dropdown.Item>Failure with Credit</Dropdown.Item>
      <Dropdown.Item>Failure</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
 <PageTitle title={"Pending Recharges"}/>
 <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>
  </main>
  )
}

export default PendingRecharge