import React from 'react'

const PageTitle = ({title}) => {
  return (
    <div class="page-header">
        <div class="row align-items-end">
          <div class="col-sm mb-sm-0">
            <h1 class="page-header-title">{title}</h1>
          </div>

        </div>
      </div>
  )
}

export default PageTitle