import React, { useState } from 'react'
import { Dropdown, Offcanvas } from 'react-bootstrap'

const ActionDropdown = ({updateTitle,updateForm,options,hasClass=false}) => {
    const [showUpdate, setShowUpdate] = useState(false);
  return (
    <>
    <Dropdown className='action-dropdown'>
    <Dropdown.Toggle className="btn btn-secondary btn-sm"  id="dropdown-basic">Actions </Dropdown.Toggle>

    <Dropdown.Menu class=" dropdown-menu-end">
      <Dropdown.Item onClick={()=>{setShowUpdate(true)}}>Update</Dropdown.Item>
      {options.map(item=><Dropdown.Item>{item}</Dropdown.Item>)}
    </Dropdown.Menu>
  </Dropdown>

     {/* Update Offcanvas Start */}

     <Offcanvas
     show={showUpdate}
     onHide={() => {
       setShowUpdate(false);
     }}
     placement="end" className={hasClass && "w-50"}
   >
     <Offcanvas.Header closeButton>
       <Offcanvas.Title>{updateTitle}</Offcanvas.Title>
     </Offcanvas.Header>
     <Offcanvas.Body>{updateForm()}</Offcanvas.Body>
   </Offcanvas>

   {/* Update Offcanvas End */}
   </>
  )
}

export default ActionDropdown