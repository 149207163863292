import React from 'react'
import { ActionDropdown, DataTable, PageTitle } from '../components'
import { Dropdown } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import { RiFileCopyLine } from "react-icons/ri";

const Admin = () => {
  const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">
    <div class="">
        <div class="input-group mb-3">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
    Username
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>
    </div>
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
    
    <div class="col-sm-auto">
      <a  type="button" class="btn btn-secondary">+ Create New</a>
    </div>
    
  </div>

</div>

const updateForm=()=> {
  return <>
   <form>
          
          <div class="row">
            <div class="col-sm-6 mb-4">
              <label for="adminname" class="form-label">Admin Name</label>
              <input type="text" class="form-control mb-2" name="createadminname" id="adminname" placeholder="Enter admin name" aria-label="name"/>
            </div>

            <div class="col-sm-6 mb-4">
              <label for="username" class="form-label">Username</label>
              <input type="text" class="form-control mb-2" name="createusername" id="username" placeholder="Enter username" aria-label="name"/>
            </div>

            <div class="col-sm-6 mb-4">
              <label for="email" class="form-label">Email</label>
              <input type="text" class="form-control mb-2" name="createemail" id="email" placeholder="Enter email" aria-label="name"/>
            </div>

            <div class="col-sm-6 mb-4">
              <label for="phonenumber" class="form-label">Phone Number</label>
              <input type="text" class="form-control mb-2" name="createphonenumber" id="phonenumber" placeholder="Enter phone number" aria-label="name"/>
            </div>

            <div class="col-sm-6 mb-4">
              <label for="phonenumber" class="form-label">Password</label>
              <div class="input-group input-group-merge" data-hs-validation-validate-class>
                <input type="password" class="js-toggle-password form-control form-control-lg" name="password" id="signupSimpleLoginPassword" placeholder="Enter Password" aria-label="8+ characters required" required minlength="8"
                      data-hs-toggle-password-options='{
                       "target": "#changePassTarget",
                       "defaultClass": "bi-eye-slash",
                       "showClass": "bi-eye",
                       "classChangeTarget": "#changePassIcon"
                     }'/>
                <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;">
                  <i id="changePassIcon" class="bi-eye"></i>
                </a>
              </div>
            </div>

            <div class="col-sm-6 mb-4">
              <label for="phonenumber" class="form-label">Confirm Password</label>
              <div class="input-group input-group-merge" data-hs-validation-validate-class>
                <input type="password" class="js-toggle-password form-control form-control-lg" name="password" id="signupSimpleLoginPassword" placeholder="Enter Password" aria-label="8+ characters required" required minlength="8"
                      data-hs-toggle-password-options='{
                       "target": "#changePassTarget",
                       "defaultClass": "bi-eye-slash",
                       "showClass": "bi-eye",
                       "classChangeTarget": "#changePassIcon"
                     }'/>
                <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;">
                  <i id="changePassIcon" class="bi-eye"></i>
                </a>
              </div>
            </div>

          </div>
          
        </form>

        <p class="text-secondary fw-bold fs-3">Permission</p>

        <table class="table table-thead-bordered table-responsive">
          <thead class="thead-light">
            <tr>
              <th>API</th>
              <th>View</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable-row">
              <td>Dashboard</td>
              <td><input type="checkbox" class="form-check-input"/></td>
              <td><input type="checkbox" class="form-check-input"/></td>
            </tr>
            <tr class="details-row border-0">
              <td colspan="3">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>User Analytics</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                    <tr>
                      <td>Services</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                    <tr>
                      <td>Earnings</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr class="clickable-row border-top-0">
              <td>Master</td>
              <td><input type="checkbox" class="form-check-input"/></td>
              <td><input type="checkbox" class="form-check-input"/></td>
            </tr>
            <tr class="details-row border-0">
              <td colspan="3">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>User Analytics</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                    <tr>
                      <td>Services</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                    <tr>
                      <td>Earnings</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr class="clickable-row border-top-0">
              <td>Admin</td>
              <td><input type="checkbox" class="form-check-input"/></td>
              <td><input type="checkbox" class="form-check-input"/></td>
            </tr>
            <tr class="details-row border-0">
              <td colspan="3">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>User Analytics</td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                      <td><input type="checkbox" class="form-check-input"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr class="clickable-row border-top-0">
              <td>Coupon Master</td>
              <td><input type="checkbox" class="form-check-input"/></td>
              <td><input type="checkbox" class="form-check-input"/></td>
            </tr>
            
          </tbody>
        </table>

        <div class="col-auto">
          <button type="button" class="btn btn-primary me-3">UPDATE</button>
          <button type="button" class="btn btn-outline-secondary">CLEAR</button>
        </div>
  
  </>
}

const tableHead=   <tr>
<th>Sr. No.</th>
<th>Username</th>
<th>Admin Name</th>
<th>Email</th>
<th>Block Status</th>
<th>Created At</th>
<th>Action</th>
</tr>

const tableRow=   <tr>
<td><span className='me-1'>9691154471</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td><span className='me-1'>Rutuja Bahadure</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>alma.lawson@example.com</td>
<td class="text-success"> Active</td>
<td>4/29/2023 12:03:59 PM</td>
<td>
  <ActionDropdown options={["Active","Deactive"]} updateTitle={"Edit Admin Details"} updateForm={updateForm} hasClass={true}/>
</td>
</tr>
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
    <PageTitle title={"Admins"}/>
    <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>

    
  </main>
  )
}

export default Admin