import React, { useState } from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiFilter, FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const ServiceReport = () => {
const [showFilter, setShowFilter] = useState(false)
  const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">
    
      <form>

        <div class="input-group">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
    User Id
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search User Id ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>

      </form>
    
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
    
    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <IoCalendarOutline size={"1rem"}/>
            </div>
        </div>
    </div>

      
    <div class="col-sm-auto">
        <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowFilter(true)}}><FiFilter size={"1rem"}/> Filter</button>
    </div>
    <Offcanvas show={showFilter} onHide={()=>{ setShowFilter(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Filter</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>

<div class="tom-select-custom mb-3">
  <label class="form-label">Service</label>
  <select class="js-select form-select" autocomplete="off"
        data-hs-tom-select-options='{
          "placeholder": "Select Service",
          "hideSearch": true
        }'>
    <option value="">Select Service</option>
    <option value="1">Mobile</option>
    <option value="2">DTH</option>
    <option value="3">Lpg Gas</option>
  </select>
</div>

<div class="tom-select-custom mb-3">
    <label class="form-label">Operator</label>
    <div class="tom-select-custom">
        <select class="js-select form-select" autocomplete="off"
              data-hs-tom-select-options='{
                "placeholder": "Select Operator",
                "hideSearch": true
              }'>
          <option value="">Select Operator</option>
          <option value="1">Airtel</option>
          <option value="2">Reliance</option>
        </select>
    </div>
</div>

<div class="tom-select-custom mb-3">
  <label class="form-label">API</label>
  <div class="tom-select-custom">
      <select class="js-select form-select" autocomplete="off"
            data-hs-tom-select-options='{
              "placeholder": "Select API",
              "hideSearch": true
            }'>
        <option value="">Select API</option>
        <option value="1">API 1</option>
        <option value="2">API 2</option>
      </select>
  </div>
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">SEARCH</button>
  <button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>
</Offcanvas.Body>
</Offcanvas>

    <div class="col-sm-auto ">
        <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>

    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-secondary"><RiFileCopyLine size={"1rem"}/> Copy</button>
    </div>
    
  </div>
</div>

const tableHead=<tr>
<th>Sr. No.</th>
<th>Number</th>
<th>User ID</th>
<th>Service</th>
<th>Operator</th>
<th>Old Balance</th>
<th>Amount</th>
<th>New Balance</th>
<th>API</th>
<th>Operator Trans Id</th>
<th>Status</th>
<th>Recharge Date</th>
</tr>

const tableRow=   <tr>
<td>1429680497</td>
<td>9632607798</td>
<td>DTH</td> 
<td>TATA PLAY (Formerly TATA SKY)</td>
<td>1,169.50</td>
<td>1,169.50</td>
<td>1,169.50</td>
<td>Mart</td>
<td>MPH2304051311240180</td>
<td class="text-success"> Success</td>
<td>4/29/2023 12:03:59 PM</td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
 <PageTitle  title={"Service Report"}/>
      <div class="row mb-5">
        <div class="col-md-3">
          <div class="card card-bordered h-100">
            <div class="card-body">
              <h3 class="fs-5">Total Amount</h3>
              <p class="fs-2 fw-bold text-secondary mb-0">15,45,94,225</p>
            </div>
          </div>
        </div>
      
        <div class="col-md-3">
          <div class="card card-bordered h-100">
            <div class="card-body">
              <h3 class="fs-5">Success Transactions</h3>
              <p class="fs-2 fw-bold text-secondary mb-0">15,45,94,225</p>
            </div>
          </div>
        </div>

      </div>
      <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow} />
    </div>

    
  </main>
  )
}

export default ServiceReport