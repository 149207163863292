import React, { useState } from 'react'
import { DataTable, PageTitle } from '../../components'
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import {  FiUpload } from "react-icons/fi";
import {  IoRefreshSharp } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";

const UpdateRechargeTransaction = () => {
  const [showUpdate, setShowUpdate] = useState(false)
  const cardHeader=()=> <div class="card-header border-0 card-header-content-sm-between">
          
  <div class="mb-2 mb-sm-0">
      <div class="input-group">
          <div class="tom-select-custom text-secondary">
          <Dropdown>
<Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>Username </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Services</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Nikola</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Nikola Tesla</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
          </div>
      
          <input type="text" class="form-control border-end-0 bg-light" name="fullName" placeholder="Search Username ..." aria-label="Search name or emails" aria-describedby="fullName"/>
      
          <button class="btn btn-white border-start-0 bg-light" type="button"><BsSearch/></button>
        </div>
  </div>

  <div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">

    <div class="col-sm-auto border border-1 bg-light ps-3 rounded">
      <div class="row align-items-center gx-0">
        <div class="col">
          <span class="text-secondary me-2">Status:</span>
        </div>

        <div class="col-auto">
          <div class="tom-select-custom tom-select-custom-end">
          <Dropdown>
          <Dropdown.Toggle className='btn btn-light filter-btn'>
            All
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">All</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Active</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Deactive</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
        <div class="input-group">
            <input type="text" id="with-calendar-field" class="js-daterangepicker form-control daterangepicker-custom-input"/>
        
            <div class="input-group-append input-group-text" onclick="$('#with-calendar-field').data('daterangepicker').toggle();">
            <i class="bi-calendar-week"></i>
            </div>
        </div>
    </div>
    
    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowUpdate(true)}}> <IoRefreshSharp size={"1rem"}/> Update All</button>
    </div>
    <Offcanvas show={showUpdate} onHide={()=>{ setShowUpdate(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Reply to Selected Pending Recharge</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>

<div class="tom-select-custom mb-3">
  <label class="form-label">Reply</label>
  <select class="js-select form-select" autocomplete="off"
        data-hs-tom-select-options='{
          "placeholder": "Select",
          "hideSearch": true
        }'>
    <option value="">Select</option>
    <option value="1">Select 1</option>
    <option value="2">Select 2</option>
  </select>
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">REPLY</button>
  <button type="button" class="btn btn-outline-secondary">Reset</button>
</div>

</form>
</Offcanvas.Body>
</Offcanvas>
    
    <div class="col-sm-auto ">
      <button type="button" class="btn btn-outline-success"><FiUpload size={"1rem"}/> Export</button>
    </div>
    
  </div>
</div>

const tableHead=   <tr>
<th>Sr. No.
  {/* <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="datatableCheckAll"/>
    <label class="form-check-label text-secondary" for="datatableCheckAll">Sr. No.</label>
  </div> */}
</th>
<th>Username</th>
<th>Number</th>
<th>Operator</th>
<th>API</th>
<th>Transaction Id</th>
<th>Amount</th>
<th>Status</th>
<th>Mode</th>
<th>Date</th>
<th>Action</th>
</tr>

const tableRow= <tr>
{/* <td>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="usersDataCheck2"/>
    <label class="form-check-label" for="usersDataCheck2">1</label>
  </div>
  </td> */}
<td><span className='me-1'>9392152700 - 2ce678b</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td><span className='me-1'>9923290044</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>JIO-Recharge</td>
<td>Mart</td>
<td><span className='me-1'>J0131010612512AAEE9</span> <span className='copy-btn'> <RiFileCopyLine /></span>
</td>
<td>210.00</td>
<td class="text-success">Success</td>
<td>GPRS</td>
<td>4/29/2023 12:03:59 PM</td>
<td>
<Dropdown className='action-dropdown'>
    <Dropdown.Toggle className="btn btn-secondary btn-sm"  id="dropdown-basic">Actions </Dropdown.Toggle>

    <Dropdown.Menu class=" dropdown-menu-end">
      <Dropdown.Item>Success with Debitnote</Dropdown.Item>
      <Dropdown.Item>Success</Dropdown.Item>
      <Dropdown.Item>Failure with Credit</Dropdown.Item>
      <Dropdown.Item>Failure</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
</td>
</tr>
  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
    <PageTitle title={"Update Recharge / BBPS Transactions"}/>
    <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow}/>
    </div>
    
  </main>

  )
}

export default UpdateRechargeTransaction