import React, { useState } from 'react'
import {  Dropdown, Offcanvas} from 'react-bootstrap';
import { ActionDropdown, DataTable, PageTitle } from '../components';
import { FiFilter } from "react-icons/fi";

const ConvenienceMaster = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showNew, setShowNew] = useState(false);
 

const cardHeader=()=>  <div class="card-header border-0 card-header-content-sm-between">
          
<div class="mb-2 mb-sm-0">
<div class="input-group mb-3">
        <div class="tom-select-custom text-secondary">
        <Dropdown>
          <Dropdown.Toggle className='btn btn-light border border-1 filter-btn'>
              Services
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>Flight</Dropdown.Item>
                <Dropdown.Item >Bus</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
</div>

<div class="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
  
  <div class="col-sm-auto">
    <button type="button" class="btn btn-outline-secondary" onClick={()=>{setShowFilter(true)}}><FiFilter size={"1rem"}/> Filter</button>
  </div>
  <Offcanvas show={showFilter} onHide={()=>{setShowFilter(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Filter</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>

<div class="tom-select-custom mb-3">
<label class="form-label">Search / Select Service</label>
<select class="js-select form-select" autocomplete="off"
data-hs-tom-select-options='{
"placeholder": "Status...",
"hideSearch": true
}'>
<option value="4">Flight</option>
<option value="1">Flight 1</option>
</select>
</div>

<div class="tom-select-custom mb-3">
<label class="form-label">Select Airline Type</label>
<select class="js-select form-select" autocomplete="off"
data-hs-tom-select-options='{
"placeholder": "Status...",
"hideSearch": true
}'>
<option value="4">Select airline</option>
<option value="1">Select airline 1</option>
</select>
</div>

<div class="col-auto mt-4">
<button type="button" class="btn btn-primary me-3">SEARCH</button>
<button type="button" class="btn btn-outline-secondary">RESET</button>
</div>

</form>
</Offcanvas.Body>
</Offcanvas>
  
  <div class="col-sm-auto">
    <button type="button" class="btn btn-secondary" onClick={()=>{setShowNew(true)}}>+ Add New Operator</button>
  </div> 
  <Offcanvas show={showNew} onHide={()=>{setShowNew(false)}} placement='end'>
<Offcanvas.Header closeButton>
<Offcanvas.Title>Add Convenience Master</Offcanvas.Title>
</Offcanvas.Header>
<Offcanvas.Body>
<form>
        <div class="tom-select-custom mb-3">
          <label class="form-label">Select Service *</label>
          <select class="js-select form-select" autocomplete="off"
                data-hs-tom-select-options='{
                  "placeholder": "Status...",
                  "hideSearch": true
                }'>
            <option value="4">Select service</option>
            <option value="1">Select service 1</option>
          </select>
        </div>

        <div class="tom-select-custom mb-3">
          <label class="form-label">Select Type *</label>
          <select class="js-select form-select" autocomplete="off"
                data-hs-tom-select-options='{
                  "placeholder": "Status...",
                  "hideSearch": true
                }'>
            <option value="4">Select Type </option>
            <option value="1">Select Type 1</option>
          </select>
        </div>

        <div class="mb-3">
          <label class="form-label" for="exampleFormControlInput1">Shopping Point Percentage</label>
          <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter shopping point %"/>
        </div>

        <div class="mb-3">
          <label class="form-label" for="exampleFormControlInput1">Prime Point Percentage</label>
          <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter prime point %"/>
        </div>

        <div class="col-auto mt-4">
          <button type="button" class="btn btn-primary me-3">SUBMIT</button>
          <button type="button" class="btn btn-outline-secondary">CANCEL</button>
        </div>
        
      </form>
</Offcanvas.Body>
</Offcanvas>
</div>
</div>

const updateForm=()=>  <form>
<div class="tom-select-custom mb-3">
  <label class="form-label">Select Service *</label>
  <select class="js-select form-select" autocomplete="off"
        data-hs-tom-select-options='{
          "placeholder": "Status...",
          "hideSearch": true
        }'>
    <option value="4">Select service</option>
    <option value="1">Select service 1</option>
  </select>
</div>

<div class="tom-select-custom mb-3">
  <label class="form-label">Select Type *</label>
  <select class="js-select form-select" autocomplete="off"
        data-hs-tom-select-options='{
          "placeholder": "Status...",
          "hideSearch": true
        }'>
    <option value="4">Select Type </option>
    <option value="1">Select Type 1</option>
  </select>
</div>

<div class="mb-3">
  <label class="form-label" for="exampleFormControlInput1">Shopping Point Percentage</label>
  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter shopping point %"/>
</div>

<div class="mb-3">
  <label class="form-label" for="exampleFormControlInput1">Prime Point Percentage</label>
  <input type="text" id="exampleFormControlInput1" class="form-control" placeholder="Enter prime point %"/>
</div>

<div class="col-auto mt-4">
  <button type="button" class="btn btn-primary me-3">SUBMIT</button>
  <button type="button" class="btn btn-outline-secondary">CANCEL</button>
</div>

</form>

const tableHead= <tr>
<th>Sr. No.</th>
<th>Service</th>
<th>Type</th>
<th>Convenience Amount</th>
<th>Shopping Point %</th>
<th>Prime Point %</th>
<th>Action</th>
</tr>

const tableRow=<tr>
<td>Flight</td>
<td>DomesticOneWay</td>
<td> 250.00</td>
<td>25.00</td>
<td> 25.00</td>
<td><ActionDropdown updateTitle={"Edit Convenience Master"} updateForm={updateForm} options={["Delete"]}/></td>
</tr>

  return (
    <main id="content" role="main" class="main">
    <div class="content container-fluid">
      <PageTitle title={"Convenience Master"}/>
      <DataTable header={cardHeader} tableHead={tableHead} tableRow={tableRow} />
    </div>

  
  </main>
  )
}

export default ConvenienceMaster